import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AuthService } from './auth.service';
import { Observable, ObservableInput, catchError, of, tap } from 'rxjs';
import { LoginResponse } from '../models/dto/login-response';
import { LoginRequest } from '../models/dto/login-request';
import { LoginResult } from '../models/dto/login-result';

/**
 * LoginService
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  /**
   * constructor
   *
   * @param _restService
   */
  constructor(
    private _restService: RestService,
    private _authService: AuthService
  ) {}

  /**
   * send login request to the backend
   *
   * @param request
   * @returns response promise
   */
  postLogin(request: LoginRequest) {
    return this._restService.post<LoginResponse>('/auth/login', request).pipe(
      tap((response: LoginResponse) => {
        if (response.success) {
          this._authService.reset();
          this._authService.token = response.token;
          this._authService.company = response.company;
          this._authService.contact = response.contact;
          this._authService.role = response.role;
          this._authService.user = response.user

          return of<LoginResult>({ success: true, role: response.role });
        } else {
          return of<LoginResult>({ success: false, role: 'Guest' });
        }
      })
    );
  }
}
