import { ClientService } from './../../modules/shared/services/client.service';
import { ClientDashboard } from './../../modules/dashboard/models/client-dashboard';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { Company } from 'src/app/models/company';
import { RestService } from '../rest.service';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { OptionsFormDto } from 'src/app/models/dialog-dto/options-form.dto';
import { LicenceFormDto } from 'src/app/models/dialog-dto/licence-form.dto';
import { OptionFormDto, OptionFormDtoUpperCase } from 'src/app/models/dialog-dto/option-form.dto';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService implements CanActivate {
  private _companies: Map<number, Company> = new Map<number, Company>();

  constructor(private _rest: RestService) {}

  loadCompanies() {
    return this._rest.get<Company[]>('/data/companies').pipe(
      map((result) => {
        for (let company of result) {
          this._companies.set(company.id, company);
        }

        return true;
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._companies.size == 0) {
      return this.loadCompanies();
    } else {
      return of(true);
    }
  }

  get companies() {
    return this._companies;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ShareClientId {
  private clientSource = new BehaviorSubject<any>({ id: 1 });
  currentClient = this.clientSource.asObservable();

  private tmpDataOptionsSource = new BehaviorSubject<OptionFormDtoUpperCase>(
    {} as OptionFormDtoUpperCase
  );
  tmpDataOptions$ = this.tmpDataOptionsSource.asObservable();

  currentClientObj: CompanyItemDto = {} as CompanyItemDto;
  currentCustomerObj: CustomerItemDto = {} as CustomerItemDto;
  currentLicence: LicenceFormDto = {} as LicenceFormDto;
  tmpDataOptions: OptionFormDto = {} as OptionFormDto;
  tmpDataList: OptionFormDtoUpperCase[] = [];
  currentLicenceGuid: string = ""

  initialRangeStartExport = new Date()
  initialRangeEndExport = new Date()

  setLogger() {
    new Promise((resolve) => {
      setInterval(() => {
      }, 1000);
    });
  }

  setClient(client: any) {
    this.clientSource.next(client);
  }

  setTmpDataOptions(options: OptionFormDtoUpperCase) {
    this.tmpDataOptionsSource.next(options);
  }

  resetTmpData() {
    let tmp = {} as OptionFormDtoUpperCase;
    this.tmpDataOptionsSource.next(tmp);
  }

  resetClient() {
    let tmp = {} as OptionFormDtoUpperCase;
    this.clientSource.next(tmp);
  }
}
