<div class="table-container">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z2 option-table" *ngIf="dataTypes.length >= dataSource.length">
  <ng-container matColumnDef="OptionTypeID">
    <th mat-header-cell *matHeaderCellDef>{{"OptionType" | translate}}</th>
    <td mat-cell *matCellDef="let option"  style="text-align: left !important; white-space: nowrap;"><mat-icon style="margin: 0 1rem -.45rem .5rem;">{{ dataTypes[dataSource.indexOf(option)].Icon }}</mat-icon>{{ dataTypes[dataSource.indexOf(option)].Name }}</td>
  </ng-container>

  <ng-container matColumnDef="OptionVersionID">
    <th mat-header-cell *matHeaderCellDef>{{"Subscription" | translate}}</th>
    <td mat-cell *matCellDef="let option">{{ dataVersions[dataSource.indexOf(option)].name }}</td>
  </ng-container>

  <ng-container matColumnDef="Value">
    <th mat-header-cell *matHeaderCellDef>{{"dialog.amount" | translate}}</th>
    <td mat-cell *matCellDef="let option">{{ option.Value }}</td>
  </ng-container>

  <ng-container matColumnDef="StartDate">
    <th mat-header-cell *matHeaderCellDef>{{"dialog.start" | translate}}</th>
    <td mat-cell *matCellDef="let option">{{ option.StartDate }}</td>
  </ng-container>

  <ng-container matColumnDef="EndDate">
    <th mat-header-cell *matHeaderCellDef>{{"dialog.end" | translate}}</th>
    <td mat-cell *matCellDef="let option">{{ option.EndDate }}</td>
  </ng-container>

  <ng-container matColumnDef="delete" *ngIf="!verify">
    <th mat-header-cell *matHeaderCellDef>{{"Delete" | translate}}</th>
    <td mat-cell *matCellDef="let option"><button (click)="removeFromCurrentList(option)" class="addSymbol"><span class="material-symbols-outlined">delete</span></button>
</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-row"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
</div>

<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
