import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.local';
import { ExportCSV } from '../models/export-csv';

/**
 * RestService is a wrapper for the http rest calls to the backend server
 * it basicly just creates the absolute url to the backend from the provided relative url
 */
@Injectable({
  providedIn: 'root',
})
export class RestService {
  /**
   * constructor
   *
   * @param _http angular http client is injected
   */
  constructor(private _http: HttpClient) {}

  /**
   * build full url to the backend
   *
   * @param endpoint
   * @returns
   */
  private builtUrl(endpoint: string) {
    const base = environment.apiUrl;
    return base + endpoint;
  }

  /**
   * send get request to the backend
   *
   * @param url
   * @returns
   */
  public get<T>(url: string) {
    return this._http.get<T>(this.builtUrl(url));
  }

  /**
   * send post request to the backend
   *
   * @param url
   * @param body
   * @returns
   */
  public post<T>(url: string, body: any) {
    return this._http.post<T>(this.builtUrl(url), body);
  }

  /**
   * send put request to the backend
   *
   * @param url
   * @param body
   * @returns
   */
  public put<T>(url: string, body: any) {
    return this._http.put<T>(this.builtUrl(url), body);
  }

  /**
   * send delete request to the backend
   *
   * @param url
   * @returns
   */
  public delete<T>(url: string) {
    return this._http.delete<T>(this.builtUrl(url));
  }

  /**
   * send get request to the backend to download a file
   *
   * @param url
   * @returns
   */
  public downloadFile(url: string, data: any) {
    return this._http.post(this.builtUrl(url), data, { responseType: 'blob' });
  }
}
