import { DialogService } from 'src/app/services/dialog.service';
import { Component, Inject } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { AddClientDto } from 'src/app/models/dialog-dto/add-client.dto';
import { LicenceDto } from 'src/app/models/licenceDto';
import { LicenceStatus } from 'src/app/models/licence-status';
import { LicenceType } from 'src/app/models/licence-type';

interface ViewData {
  guid: string;
  viewObject: string;
}

@Component({
  selector: 'app-dialog-view-contact',
  templateUrl: './dialog-view-contact.component.html',
  styleUrl: './dialog-view-contact.component.scss',
})
export class DialogViewContactComponent {
  companyData: AddClientDto = {} as AddClientDto;
  licenceData: LicenceDto = {} as LicenceDto

  status: LicenceStatus = {} as LicenceStatus
  type: LicenceType = {} as LicenceType

  constructor(
    private dataService: DataService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: ViewData
  ) {
    if (data?.guid) {
      if (data.viewObject == 'client') {
        this.dialogService
          .getEditClientDataGuid(data.guid)
          .subscribe((data) => {
            this.companyData = data;
          });
      } if (data.viewObject == "customer") {
        this.dialogService.getSelectedCustomer(data.guid).subscribe(data => {
          this.companyData = data
        })
      } if (data.viewObject == "licence") {
        this.licenceData = this.dataService.getLicenceById(data.guid) as LicenceDto
        this.status = this.dataService.getLicenceStatusById(this.licenceData.licence_status_id) as LicenceStatus
        this.type = this.dataService.getLicenceTypeById(this.licenceData.licence_type_id) as LicenceType

      }
    }
  }
}
