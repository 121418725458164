import { CompanyItemDto } from './../../models/dialog-dto/company-list-item.dto';
import {
  CompaniesService,
  ShareClientId,
} from './../../services/data/companies.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { Company } from 'src/app/models/company';
import { DialogService } from 'src/app/services/dialog.service';
import { CompanyListItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { CompanySelectDto } from 'src/app/models/dialog-dto/company-select.dto';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dialog-company-select',
  templateUrl: './dialog-company-select.component.html',
  styleUrl: './dialog-company-select.component.scss',
})
export class DialogCompanySelectComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  client: CompanyItemDto | undefined;

  clients: CompanyListItemDto[] = [];
  initialClient: number | string = "0"
  clientInformation: CompanyItemDto = {} as CompanyItemDto;

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    private dataService: DataService,
    private shareClientId: ShareClientId
  ) {
    this.form = dialogFormService.newCompanySelectForm();
    this.dialogService.getClientsList().subscribe((clients) => {
      this.clients = clients;
    });
  }
  ngOnInit(): void {
    this.form.valueChanges.subscribe((company) => {
      if (company.id != undefined && company.id != "0") {
        this.dialogService
          .getClientByCompanyId(company.id)
          .subscribe((client) => {
            // this.form.get("id")?.setValue(client.company.id);
            this.client = client;
            this.shareClientId.currentClientObj = client
          });
      }
    });
  }
  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue("")
    }
  }
}
