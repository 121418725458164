import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrl: './reset-password-confirm.component.scss'
})
export class ResetPasswordConfirmComponent {
  uuid: string = ""
  success: boolean = false

  resetPasswordConfirmForm: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', Validators.required),
    confirmPassword: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private _translateService: TranslateService,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {
    this.route.paramMap.subscribe(param => {this.uuid = param.get("uuid") as string});
  }

  reset() {
    this.dataService.resetPasswordConfirm(this.resetPasswordConfirmForm.get("password")?.value, this.uuid).subscribe(
      response => {
        console.log('Success:', response);
        this.success = true
      },
      error => {
        console.error('Error:', error);
      }
    );
  }
}
