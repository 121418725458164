import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { LicenceFormDto } from 'src/app/models/dialog-dto/licence-form.dto';
import { OptionFormDto } from 'src/app/models/dialog-dto/option-form.dto';
import { LicenceDto } from 'src/app/models/licenceDto';
import { DataService } from 'src/app/services/data.service';
import { ShareClientId } from 'src/app/services/data/companies.service';
import { DialogFormService } from 'src/app/services/dialog-form.service';

interface SetOptionsDialogData {
  guid: string
}

@Component({
  selector: 'app-dialog-set-options',
  templateUrl: './dialog-set-options.component.html',
  styleUrl: './dialog-set-options.component.scss'
})
export class DialogSetOptionsComponent {
  optionsForm: FormGroup;

  constructor(
    private shareClientId: ShareClientId,
    private dataService: DataService,
    public dialogRef: MatDialogRef<DialogSetOptionsComponent>,
    private dialogFormService: DialogFormService,
    @Inject(MAT_DIALOG_DATA) public data: SetOptionsDialogData
  ) {
    this.optionsForm = this.dialogFormService.newOptionsForm();
    if (this.data) {
      let licence = this.dataService.getLicenceById(this.data.guid) as LicenceDto
      let formatLicence: LicenceFormDto = {
        vrs: licence.vrs,
        projectName: licence.project,
        type: licence.licence_type_id,
        status: licence.licence_status_id
      }
      this.shareClientId.currentLicence = formatLicence
      this.dataService.getOptionsByLicence(this.data.guid as string).subscribe((options) => {
        this.shareClientId.tmpDataList = options
      })
    }
  }

  resetData() {
    console.log('Data Reset');

    this.shareClientId.currentClientObj = {} as CompanyItemDto;
    this.shareClientId.currentCustomerObj = {} as CustomerItemDto;
    this.shareClientId.currentLicence = {} as LicenceFormDto;
    this.shareClientId.tmpDataList = [];
    this.shareClientId.tmpDataOptions = {} as OptionFormDto;

    this.shareClientId.resetClient();
    this.shareClientId.resetTmpData();
  }

  save() {
    this.dialogFormService.saveSetOptionsForm(
      this.data.guid,
      this.shareClientId.tmpDataList
    ).subscribe((response) => {
      if(response.success) {
        this.dialogRef.close(response.success);
      }
    })
  }
}
