<div class="footer">
  <div>
    {{"footer.copyright" | translate: copyrightParam}}
  </div>
  <div>
    <a href="https://www.accellence.de/impressum" class="underline">{{"footer.imprint" | translate}}</a> -
    <a href="https://www.accellence.de/datenschutz" class="underline">{{"footer.privacy" | translate}}</a>
  </div>
</div>

<div class="footer_logo">
  <img src="/assets/mini_acc_logo.png" alt="logo">
</div>
