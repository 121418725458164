<form [formGroup]="form">
  <h3>{{"Contact" | translate}}</h3>
  <mat-form-field class="w-full">
    <mat-label>{{"Firstname" |translate}}</mat-label>
    <input matInput placeholder="" formControlName="firstname" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('firstname')?.dirty && form.get('firstname')?.value != initialContact?.firstname" (click)="undoChanges('firstname', initialContact?.firstname); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('firstname')?.invalid) {
    <mat-error>{{"dialog.firstnameError" |translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"Lastname" |translate}}</mat-label>
    <input matInput placeholder="" formControlName="lastname" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('lastname')?.dirty && form.get('lastname')?.value != initialContact?.lastname" (click)="undoChanges('lastname', initialContact?.lastname); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('lastname')?.invalid) {
    <mat-error>{{"dialog.lastnameError" |translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"E-Mail" |translate}}</mat-label>
    <input matInput placeholder="" formControlName="email" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('email')?.dirty && form.get('email')?.value != initialContact?.email" (click)="undoChanges('email', initialContact?.email); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('email')?.invalid) {
    <mat-error>{{"dialog.emailError" | translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"Phone" |translate}}</mat-label>
    <input matInput placeholder="" formControlName="phone" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('phone')?.dirty && form.get('phone')?.value != initialContact?.phone" (click)="undoChanges('phone', initialContact?.phone); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('phone')?.invalid) {
    <mat-error>Please enter a phone number</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"Handy" |translate}}</mat-label>
    <input matInput placeholder="" formControlName="handy" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('handy')?.dirty && form.get('handy')?.value != initialContact?.handy" (click)="undoChanges('handy', initialContact?.handy); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('handy')?.invalid) {
    <mat-error>Please enter a handy number</mat-error>
    }
  </mat-form-field>
</form>
