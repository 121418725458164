<h1 mat-dialog-title class="dialog-header">{{"ChangePassword" | translate}}</h1>
<mat-divider style="margin: 1rem !important;"></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form" id="changePassword" class="changePassword">
    <h3 class="ml-2">{{"ChangePassword" | translate}}</h3>
    <mat-form-field class="w-full first">
      <mat-label>{{"CurrentPassword" | translate}}</mat-label>
      <input matInput type="password" placeholder="" formControlName="currentPassword" />
    </mat-form-field>
    <mat-divider style="margin-bottom: 1rem"></mat-divider>
    <mat-form-field class="w-full">
      <mat-label>{{"NewPassword" | translate}}</mat-label>
      <input matInput type="password" placeholder="" formControlName="newPassword" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{"ConfirmNewPassword" | translate}}*</mat-label>
      <input matInput type="password" placeholder="" formControlName="confirmNewPassword"/>
      <mat-error>{{"PasswordError" | translate}}</mat-error>
    </mat-form-field>
  </form>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-flat-button
          cdkFocusInitial
          color="primary"
          class="ml-2"
          (click)="save()"
        >
        {{"Save" | translate}}
        </button>
      </div>
</mat-dialog-content>
