import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { ContactFormDto } from 'src/app/models/dialog-dto/contact-form.dto';

@Component({
  selector: 'app-dialog-contact-form',
  templateUrl: './dialog-contact-form.component.html',
  styleUrl: './dialog-contact-form.component.scss'
})
export class DialogContactFormComponent {
  @Input()
  form: FormGroup;

  @Input()
  initialContact: ContactFormDto | undefined

  constructor(private dialogFormService: DialogFormService) {
    this.form = dialogFormService.newContactForm();
  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue("")
    }
  }
}
