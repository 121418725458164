import { Subject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DeleteLicenceDto } from 'src/app/models/licenceDto';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { LicenceType } from 'src/app/models/licence-type';
import { LicenceStatus } from 'src/app/models/licence-status';
import { AddClientDto } from 'src/app/models/dialog-dto/add-client.dto';
import { switchMap, tap } from 'rxjs/operators';

interface DeleteLicenceDialogData {
  guid: string;
}

@Component({
  selector: 'app-dialog-delete-licence',
  templateUrl: './dialog-delete-licence.component.html',
  styleUrls: ['./dialog-delete-licence.component.scss']
})
export class DialogDeleteLicenceComponent implements OnInit {
  guid: string = "";
  licence: DeleteLicenceDto = {} as DeleteLicenceDto;  // Provide a default value
  client: AddClientDto = {company: {name: ""}} as AddClientDto;  // Provide a default value
  customer: CustomerItemDto = {company: {name: ""}} as CustomerItemDto;  // Provide a default value
  licenceType: LicenceType = {name: ""} as LicenceType;  // Provide a default value
  licenceStatus: LicenceStatus = {status: ""} as LicenceStatus;  // Provide a default value

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<DialogDeleteLicenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteLicenceDialogData
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.guid = this.data.guid;

      this.dialogService.getEditLicenceData(this.guid).pipe(
        tap(data => this.licence = data),
        switchMap(() => this.dialogService.getSelectedCustomer(this.licence.CustomerID)),
        tap(customer => this.customer = customer),
        switchMap(() => this.dialogService.getSelectedClient(this.customer.client.id)),
        tap(client => this.client = client),
        switchMap(async () => this.dataService.getLicenceStatusById(this.licence.LicenceStatusID)),
        tap(status => this.licenceStatus = status as LicenceStatus),
        switchMap(async () => this.dataService.getLicenceTypeById(this.licence.LicenceTypeID)),
        tap(type => this.licenceType = type as LicenceType)
      ).subscribe({
        next: () => {
          console.log('Data loaded successfully');
        },
        error: (err) => {
          console.error('Error loading data', err);
        }
      });
    }
  }

  confirm(): void {
    this.dialogFormService.confirmDeleteLicenceForm(this.guid).subscribe(result => {
      if (result.success) {
        this.dialogRef.close(result.success);
      }
    });
  }
}
