<span matSnackBarLabel>
  {{ data?.text | translate}}
</span>
<span matSnackBarActions>
  <button
    mat-button
    color="primary"
    (click)="snackBarRef.dismissWithAction()"
  >
    OK
  </button>
</span>
