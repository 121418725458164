<form [formGroup]="form">
  <mat-form-field class="w-full">
    <mat-label>{{"Licence" | translate}}</mat-label>
    <mat-select formControlName="id">
      @for (licence of licences; track $index) {
      <mat-option [value]="licence.ID" >{{ licence.Project }}</mat-option>
      }
    </mat-select>
    <button mat-icon-button class="undoButton" *ngIf="form.get('id')?.dirty && form.get('id')?.value != initiaLicence" (click)="undoChanges('id', initiaLicence); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
  </mat-form-field>
</form>
