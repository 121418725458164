<h1 mat-dialog-title class="dialog-header" *ngIf="!guid">{{"Add Customer" | translate}}</h1>
<h1 mat-dialog-title class="dialog-header" *ngIf="guid">{{"Edit Customer" | translate}}</h1>

<mat-dialog-content class="mat-typography">
  <mat-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="clientSelectForm">
      <ng-template matStepLabel>{{"Client" | translate}}</ng-template>

      <h3>{{"dialog.selectclient" | translate}}</h3>

      <app-dialog-company-select
        [form]="clientSelectForm" [client]="initialClient"
      ></app-dialog-company-select>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Next" | translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="companyForm">
      <ng-template matStepLabel>{{"Company" | translate}}</ng-template>

      <app-dialog-company-form [form]="companyForm" [initialClient]="initialCustomer?.company" [tags]="selectedTags" (changeEvent)="getTagsFromComponent($event)"></app-dialog-company-form>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Next" | translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="contactForm">
      <ng-template matStepLabel>{{"Contact" | translate}}</ng-template>

      <app-dialog-contact-form [form]="contactForm" [initialContact]="initialCustomer?.contact"></app-dialog-contact-form>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Next" | translate}}
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{"Confirmation" | translate}}</ng-template>

      <div>
        <h3>{{"Confirmation" | translate}}</h3>
        <h4>{{"Company" |translate}}</h4>
        <p>
          {{ companyForm.get("name")?.value }}<br />
          {{ companyForm.get("street")?.value }}<br />
          {{ companyForm.get("zipcode")?.value }}
          {{ companyForm.get("city")?.value }}<br />
          {{ companyForm.get("country")?.value }}
        </p>
        <h4>{{"Contact" | translate}}</h4>
        <p>
          {{ contactForm.get("firstname")?.value }}
          {{ contactForm.get("lastname")?.value }}<br />
          {{ contactForm.get("email")?.value }}<br />
          {{ contactForm.get("phone")?.value }}<br />
          {{ contactForm.get("handy")?.value }}
        </p>
      </div>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button mat-flat-button cdkFocusInitial color="primary" class="ml-2" (click)="save()">
          {{"Save" | translate}}
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
