<app-header></app-header>
<form [formGroup]="resetPasswordForm">
  <div class="rounded w-96 shadow-md mx-auto mt-32 mb-6 bg-white">
    <div class="login-header h-52 pl-52 text-xl pt-20 rounded-t">
      <span class="vimacc font-bold"
        >vima<span class="vimacc-cc font-bold">cc</span></span
      ><span class="text-xs align-top">®</span><br />
      <span class="vimacc-ol">Online Licensing</span>
    </div>
    <div class="w-96 pl-16 pr-16 pt-16">
      <div class="mb-4 text-lg">
        {{"login.reset" | translate}}
      </div>
      <mat-form-field class="w-64">
        <mat-label>{{ "login.email" | translate }}</mat-label>
        <input matInput placeholder="" formControlName="email" />
      </mat-form-field>
    </div>
    <div class="flex justify-center">
      <span class="text-xs text-center pb-2">
        <a [routerLink]="['/login']" class="text-gray-400">
          {{ "login.backToLogin" | translate }}
        </a>
      </span>
    </div>
    <div *ngIf="requested">
      <p class="text-center">{{ "login.requested1" | translate  }}</p>
      <p class="text-center">{{ "login.requested2" | translate  }}</p>
    </div>
    <div class="m-6">
      <button
        mat-flat-button
        [disabled]="!resetPasswordForm.valid"
        color="primary"
        class="w-full mb-12"
        (click)="reset()">
        {{ "login.reset" | translate }}
      </button>
    </div>
  </div>
</form>

<app-footer></app-footer>
