<form [formGroup]="form">
  <h3>Project (Licence)</h3>
  <mat-form-field class="w-full">
    <mat-label>VRS</mat-label>
    <input matInput placeholder="" formControlName="vrs" [value]="initialLicence?.vrs"/>
    <button mat-icon-button class="undoButton" *ngIf="form.get('vrs')?.dirty && form.get('vrs')?.value != ''" (click)="undoChanges('vrs',''); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
  <mat-error *ngIf="form.get('vrs')?.invalid">
  {{"dialog.vrsError" | translate}}
  </mat-error>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"dialog.projectName" | translate}}</mat-label>
    <input matInput placeholder="" formControlName="projectName" [value]="initialLicence?.project"/>
    <button mat-icon-button class="undoButton" *ngIf="form.get('projectName')?.dirty && form.get('projectName')?.value != ''" (click)="undoChanges('projectName',''); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('projectName')?.invalid) {
    <mat-error>{{"dialog.projectError" | translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"dialog.type" | translate}}</mat-label>
    <mat-select formControlName="type" [value]="initialLicence?.licence_type_id">
      @for (type of licenceTypes; track $index) {
      <mat-option [value]="type.id">{{ type.name }}</mat-option>
      }
    </mat-select>
    <button mat-icon-button class="undoButton" *ngIf="form.get('type')?.dirty && form.get('type')?.value != ''" (click)="undoChanges('type',''); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"dialog.status" | translate}}</mat-label>
    <mat-select formControlName="status" [value]="initialLicence?.licence_status_id">
      @for (status of licenceStatuses; track $index) {
      <mat-option [value]="status.id">
        {{ status.status }}
      </mat-option>
      }
    </mat-select>
    <button mat-icon-button class="undoButton" *ngIf="form.get('status')?.dirty && form.get('status')?.value != ''" (click)="undoChanges('status',''); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
  </mat-form-field>
</form>
<div *ngIf="isSuspended" class="isSuspended">
  <mat-icon>warning</mat-icon>
  <p> {{"dialog.IsSuspendedInfo" | translate}} </p>
</div>
