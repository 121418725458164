<mat-dialog-content class="mat-typography" style="margin-bottom: 2rem">
  <h1>Delete Licence</h1>
  <h3 class="primary">{{"removeTextLicence" | translate}}</h3>

  <h4>Client:</h4>
  <div *ngIf="client">
    {{ client.company.name }}
    <p>
      {{ client.company.street }},
      {{ client.company.zipcode }}
      {{ client.company.city }}
    </p>
    {{ client.company.country }}
  </div>

  <h4>Customer:</h4>
  <div *ngIf="customer">
    {{ customer.company.name }}
    <p>
      {{ customer.company.street }},
      {{ customer.company.zipcode }}
      {{ customer.company.city }}
    </p>
    {{ customer.company.country }}
  </div>

  <h4>Licence:</h4>
  <div *ngIf="licence && licenceStatus && licenceType">
    <p>{{ licence.Project }}</p>
    <p>{{ licence.VRS }}</p>
    <p>{{ licenceStatus.status }}</p>
    <p>{{ licenceType.name }}</p>
  </div>
</mat-dialog-content>

<div class="absolute right-4 bottom-4">
  <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
  <button
    mat-flat-button
    cdkFocusInitial
    color="primary"
    class="ml-2"
    (click)="confirm()"
  >
    Delete
  </button>
</div>
