<form [formGroup]="form" class="flex flex-col items-start">
  <h3>{{"Address" | translate}}</h3>
  <mat-form-field class="w-full">
    <mat-label>{{"Company" | translate}}</mat-label>
    <input matInput placeholder="" formControlName="name" required />
    <button mat-icon-button class="undoButton" *ngIf="form.get('name')?.dirty && form.get('name')?.value != initialClient?.name" (click)="undoChanges('name', initialClient?.name); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('name')?.invalid) {
    <mat-error>{{"dialog.companyError" | translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"Country"| translate}}</mat-label>
    <input matInput placeholder="" formControlName="country" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('country')?.dirty && form.get('country')?.value != initialClient?.country" (click)="undoChanges('country', initialClient?.country); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('country')?.invalid) {
    <mat-error>{{"dialog.countryError" | translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"City" | translate}}</mat-label>
    <input matInput placeholder="" formControlName="city" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('city')?.dirty && form.get('city')?.value != initialClient?.city" (click)="undoChanges('city', initialClient?.city); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('city')?.invalid) {
    <mat-error>{{"dialog.cityError" | translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"ZIP" | translate}}</mat-label>
    <input matInput placeholder="" formControlName="zipcode" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('zipcode')?.dirty && form.get('zipcode')?.value != initialClient?.zipcode" (click)="undoChanges('zipcode', initialClient?.zipcode); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('zipcode')?.invalid) {
    <mat-error>{{"dialog.zipError" | translate}}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{"Street" | translate}}</mat-label>
    <input matInput placeholder="" formControlName="street" />
    <button mat-icon-button class="undoButton" *ngIf="form.get('street')?.dirty && form.get('street')?.value != initialClient?.street" (click)="undoChanges('street', initialClient?.street); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
    @if(form.get('street')?.invalid) {
    <mat-error>{{"dialog.streetError" | translate}}</mat-error>
    }
  </mat-form-field>
  <div class="w-full flex justify-between gap-8 mb-4">
  <mat-form-field class="w-full h-20 max-h-20">
    <mat-label>{{ "Tags" | translate }}</mat-label>
    <mat-select formControlName="tags">
      @for (tag of availableTags; track $index) {
      <mat-option [value]="tag.Name" (click)="appendTag(tag)">{{
        tag.Name
      }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="w-full flex flex-wrap tag-container">
    @for (tag of tags; track $index) {
      <span class="tag" [style]="'background-color:' + tag.Color"><span class="brightness-50 saturate-200" [style]="'color: ' + tag.Color">{{tag.Name}}</span><span style="margin: 0 -.5rem 0 .75rem" class="brightness-50 saturate-200 cursor-pointer" [style]="'color: ' + tag.Color" (click)="removeTag(tag)">x</span></span>
    }
</div>
</div>
</form>
