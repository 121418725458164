<h1 mat-dialog-title>{{"deleteClient" | translate }}</h1>

<mat-dialog-content class="mat-typography">
  <h3 class="primary">{{"deleteTextClient" | translate}}</h3>
  <h4>{{"Company" | translate}}</h4>
  <p>
    {{ client.company.name }}<br />
    {{ client.company.street }}<br />
    {{ client.company.zipcode}} {{ client.company.city }}<br />
    {{ client.company.country }}
  </p>
  <h4>Contact</h4>
  <p>
    {{ client.contact.firstname }}
    {{ client.contact.lastname }}<br />
    {{ client.contact.email }}<br />
    {{ client.contact.phone }}<br />
    {{ client.contact.handy }}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{"Cancel"|translate}}</button>
  <button mat-flat-button cdkFocusInitial (click)="confirm()" color="primary">{{"Confirm" | translate}}</button>
</mat-dialog-actions>
