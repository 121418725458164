import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.local';

/**
 * LanguageService handles changing the language inside the web application or loading the initial used language
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _localStorageKey = 'volLang';
  private _defaultLanguage = environment.defaultLanguage;
  private _allowedLangs: string[] = environment.allowedLanguages;
  private _lang: BehaviorSubject<string>;

  /**
   * constructor
   *
   * @param _translateService
   */
  constructor(private _translateService: TranslateService) {
    const savedLanguage = localStorage.getItem(this._localStorageKey);
    const dl = savedLanguage || this._defaultLanguage;

    this._lang = new BehaviorSubject<string>(dl);
    console.log('init', dl);

    this._translateService.use(dl);
  }

  /**
   * getter for the currently used language
   * this returns a snapshot of the current value
   * to get changes to the currently used langauge, use the observable from lang
   */
  get currentLanguage() {
    return this._lang.value;
  }

  /**
   * getter for lang observable holding current used value for the lang
   */
  get lang() {
    return this._lang.asObservable();
  }

  /**
   * setter to set the current used language in the application
   * this is also stored in localstorage
   */
  set currentLanguage(l: string) {
    const lang = l.toLocaleLowerCase();

    if (lang != this.currentLanguage && this._allowedLangs.includes(lang)) {
      const sub = this._translateService.use(l);

      sub.subscribe(() => {
        // save used language in localstorage for later uses or refreshes
        localStorage.setItem(this._localStorageKey, l);

        // notify subscribers of the new language
        this._lang.next(l);
      });
    }
  }

  getLocale() {
    return this.currentLanguage == 'de' ? 'de-DE' : 'en-GB';
  }
}
