import { LicenceStatus } from 'src/app/models/licence-status';
import { DataService } from 'src/app/services/data.service';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { ShareClientId } from './../../services/data/companies.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { LicenceFormDto } from 'src/app/models/dialog-dto/licence-form.dto';
import { OptionFormDto, OptionFormDtoUpperCase } from 'src/app/models/dialog-dto/option-form.dto';
import { CommonModule } from '@angular/common';
import { MatTableOptionsComponent } from '../mat-table-options/mat-table-options.component';
import { LicenceType } from 'src/app/models/licence-type';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-verify-licence',
  templateUrl: './dialog-verify-licence.component.html',
  styleUrl: './dialog-verify-licence.component.scss',
})
export class DialogVerifyLicenceComponent implements OnInit, AfterViewInit{
  customer: CustomerItemDto | undefined
  company: CompanyItemDto | undefined
  licence: LicenceFormDto | undefined;
  options: OptionFormDtoUpperCase[] = [] as OptionFormDtoUpperCase[];
  licenceStatus: LicenceStatus  = {} as LicenceStatus
  licenceType: LicenceType = {} as LicenceType

  constructor(
    private shareClientId: ShareClientId,
    private cdr: ChangeDetectorRef,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    if( this.shareClientId.currentClientObj && Object.keys(this.shareClientId.currentClientObj).length != 0) {
      this.company = this.shareClientId.currentClientObj;
    }
    if( this.shareClientId.currentCustomerObj && Object.keys(this.shareClientId.currentCustomerObj).length != 0) {
      this.customer = this.shareClientId.currentCustomerObj;
    }
    if(this.shareClientId.currentLicence && Object.keys(this.shareClientId.currentLicence).length != 0) {
    this.licence = this.shareClientId.currentLicence;

    this.licenceStatus = this.dataService.getLicenceStatusById(this.licence.status) as LicenceStatus
    this.licenceType = this.dataService.getLicenceTypeById(this.licence.type) as LicenceType
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if(!( this.shareClientId.currentClientObj && Object.keys(this.shareClientId.currentClientObj).length != 0)) {
      let container = document.getElementById("verifyGrid") as HTMLDivElement
      container.classList.add("verifyGrid2")
      container.classList.remove("verifyGrid")
    }
  }
}
