<form [formGroup]="form">
  <h3>{{ "LicenceOptions" | translate }}</h3>

  <app-mat-table-options [verify]="false"></app-mat-table-options>

  <div style="display: flex">
    <mat-form-field class="w-full">
      <mat-label>{{ "OptionType" | translate }}</mat-label>
      <mat-select formControlName="OptionTypeID">
        <mat-option *ngFor="let type of optionTypes" [value]="type.id">{{
          type.name
        }}</mat-option>
      </mat-select>
      <mat-error>{{
        "dialog.typeError" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "Subscription" | translate }}</mat-label>
      <mat-select formControlName="OptionVersionID">
        <mat-option
          *ngFor="let version of optionVersions"
          [value]="version.id"
          >{{ version.name }}</mat-option
        >
      </mat-select>
      <mat-error>{{
        "dialog.versionError" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "dialog.amount" | translate }}</mat-label>
      <input type="number" matInput placeholder="0" formControlName="Value" />
      <mat-error>{{
        "dialog.amountError" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "dialog.start" | translate }}</mat-label>
      <input type="date" matInput placeholder="" formControlName="StartDate" />
      <mat-error>{{
        "dialog.startError" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "dialog.end" | translate }}</mat-label>
      <input type="date" matInput placeholder="" formControlName="EndDate" />
      <mat-error>{{
        "dialog.endError" | translate
      }}</mat-error>
    </mat-form-field>
    <div>
      <button class="addSymbol2" (click)="addToCurrentList()"><span class="material-symbols-outlined addSymbolSpan">
        add
        </span></button>
    </div>


    <div
      *ngIf="!checkIfEarlier"
      id="errorIfEarlier"
      [ngClass]="{ aBitHigher: !checkIfLogicalDate }"
      class="ifEarlierError"
    >
      {{ "dialog.earlierError" | translate }}
    </div>
    <div *ngIf="!checkIfLogicalDate" class="ifEarlierError">
      {{ "dialog.logicError" | translate }}
    </div>
  </div>
</form>

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
/>
