import { ContactUpper } from './../../models/contact';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddCustomerDto } from 'src/app/models/dialog-dto/add-customer.dto';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DialogAddClientComponent } from '../dialog-add-client/dialog-add-client.component';
import { DataService } from 'src/app/services/data.service';
import { UserDto } from 'src/app/models/user-dto';
import { Role, Tag, UserUpper } from 'src/app/models/user';

interface DeleteUserDialogData {
  guid: string
}

@Component({
  selector: 'app-dialog-delete-user',
  templateUrl: './dialog-delete-user.component.html',
  styleUrl: './dialog-delete-user.component.scss'
})
export class DialogDeleteUserComponent {
  guid: string = '';

  user: UserUpper = {} as UserUpper
  selectedContact: ContactUpper = {} as ContactUpper
  selectedRole: Role = {} as Role
  selectedTags: Tag[] = []

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogAddClientComponent>,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: DeleteUserDialogData
  ) {

    if (data?.guid && data.guid !== '') {
      this.guid = data.guid;

      this.dataService
        .getUser(data.guid)
        .subscribe((data) => {
          this.user = data as unknown as UserUpper

          this.dataService.getTagsByUser(this.user.ID).subscribe((tags) => {
            this.selectedTags = tags
          })

          this.dataService.getRoleByUser(this.user.RoleID).subscribe((role) => {
            this.selectedRole = role
          })

          this.dataService.getContactByID(this.user.ContactID).subscribe((contact) => {
            this.selectedContact = contact as unknown as ContactUpper
          })

    });
    }
  }

  confirm() {
    this.dialogFormService.confirmDeleteUserForm(this.guid).subscribe(result => {
      if (result.success) {
        this.dialogRef.close(result.success)
      }
    });
  }
}
