<mat-dialog-content class="mat-typography">
  <ng-container >
  <h1 mat-dialog-title class="dialog-header">{{"Licence Options" | translate}}</h1>
  <ng-template matStepLabel>Options</ng-template>
  <mat-divider style="margin-bottom: .5rem;"></mat-divider>
  <div class="flex OptionOrigin">
    <div>{{"Client" | translate}}: {{data.client.name}} </div>
    <div> | </div>
    <div>{{"Customer" | translate}}: {{data.customer.name}} </div>
    <div> | </div>
    <div>{{"Licence" | translate}}: {{licence?.project}}</div>
  </div>
  <mat-divider style="margin: .5rem 0;"></mat-divider>
  <app-mat-table-options [verify]="true"> </app-mat-table-options>
</ng-container>
<ng-container>
<button mat-flat-button
mat-dialog-close
cdkFocusInitial
color="primary"
class="float-right">OK</button>
</ng-container>
</mat-dialog-content>
