import { DialogService } from 'src/app/services/dialog.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { FormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { LicenceType } from 'src/app/models/licence-type';
import { LicenceStatus } from 'src/app/models/licence-status';
import { ShareClientId } from 'src/app/services/data/companies.service';
import { LicenceDto } from 'src/app/models/licenceDto';

@Component({
  selector: 'app-dialog-licence-form',
  templateUrl: './dialog-licence-form.component.html',
  styleUrl: './dialog-licence-form.component.scss',
})
export class DialogLicenceFormComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  initialLicence: LicenceDto | undefined;

  licenceTypes: LicenceType[] = [];
  licenceStatuses: LicenceStatus[] = [];

  isSuspended: boolean = false

  constructor(
    private dialogFormService: DialogFormService,
    public dataService: DataService,
    private shareClientId: ShareClientId
  ) {
    this.form = this.dialogFormService.newLicenceForm();
  }

  async ngOnInit(): Promise<void> {
    this.form.valueChanges.subscribe((licence) => {
      if (this.form.valid) {
        this.shareClientId.currentLicence = licence;
      }
    });

    this.form.get("status")?.valueChanges.subscribe(status => {
      this.isSuspended = status == 2
    })

    await new Promise((r) => {
      setTimeout(r, 200);
    });
    this.dataService.licenceTypes.forEach((val) => this.licenceTypes.push(val));
    this.dataService.licenceStatuses.forEach((val) =>
      this.licenceStatuses.push(val)
    );
    if (this.initialLicence) {
      this.form.get('vrs')?.setValue(this.initialLicence?.vrs);
      this.form.get('projectName')?.setValue(this.initialLicence?.project);
    }
  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue("")
    }
  }
}
