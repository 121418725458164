import { LicenceDto } from './../../models/licenceDto';
import { Component, Inject, OnInit } from '@angular/core';
import { MatTableOptionsComponent } from "../mat-table-options/mat-table-options.component";
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShareClientId } from 'src/app/services/data/companies.service';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { OverviewClientDto } from 'src/app/modules/overview/models/overview-client-dto';
import { OverviewCustomerDto } from 'src/app/modules/overview/models/overview-customer-dto';
import { CompanySelectDto } from 'src/app/models/dialog-dto/company-select.dto';
import { OverviewCompanyDto } from 'src/app/modules/overview/models/overview-company-dto';

interface ViewOptionsDialogData {
  guid: string;
  client: OverviewCompanyDto;
  customer: OverviewCompanyDto;
}

@Component({
  selector: 'app-dialog-view-options',
  templateUrl: './dialog-view-options.component.html',
  styleUrl: './dialog-view-options.component.scss'
})

export class DialogViewOptionsComponent{
  guid = ""

  licence: LicenceDto | undefined = {} as LicenceDto
  constructor(
    private dataService: DataService,
    private dialogService: DialogService,
    private shareClientId: ShareClientId,
    @Inject(MAT_DIALOG_DATA) public data: ViewOptionsDialogData
  ) {
    if (this.data) {
      this.dataService.getOptionsByLicence(this.data.guid as string).subscribe((options) => {
        this.shareClientId.tmpDataList = options
      })

      this.licence = this.dataService.getLicenceById(this.data.guid)
    }
  }

}
