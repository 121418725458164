import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NotFoundErrorComponent } from './components/not-found-error/not-found-error.component';
import { authGuard } from './guards/auth.guard';
import { DataService } from './services/data.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './components/reset-password-confirm/reset-password-confirm.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';

const routes: Routes = [
  // root route redirects to login by default
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  // login route
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'reset',
    component: ResetPasswordComponent,
  },

  {
    path: 'reset/:uuid',
    component: ResetPasswordConfirmComponent,
  },

  {
    path: 'setpassword/:id',
    component: SetPasswordComponent,
  },



  // everything with overview in it is directed to the lazy loaded overview module
  {
    path: 'overview',
    data: { role: ['Admin', 'Client', 'Customer', 'Operator'] },
    canActivate: [authGuard, DataService],
    loadChildren: () =>
      import('./modules/overview/overview.module').then(
        (m) => m.OverviewModule
      ),
  },

  // everything with dashboard in it is directed to the lazy loaded dashboard module
  {
    path: 'dashboard',
    data: { role: ['Admin', 'Client', 'Customer', 'Operator'] },
    canActivate: [authGuard, DataService],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: 'profile',
    data: { role: ['Admin', 'Client', 'Customer', 'Operator'] },
    canActivate: [authGuard, DataService],
    loadChildren: () =>
      import('./modules/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
  },

  // catch all other routes and show the 404 error page
  {
    path: '**',
    component: NotFoundErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
