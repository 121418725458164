import { Customer } from './../../models/customer';
import { DataService } from 'src/app/services/data.service';
import { ShareClientId } from 'src/app/services/data/companies.service';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MatStepper } from '@angular/material/stepper';
import {
  OptionFormDto,
  OptionFormDtoUpperCase,
} from 'src/app/models/dialog-dto/option-form.dto';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { LicenceFormDto } from 'src/app/models/dialog-dto/licence-form.dto';
import { LicenceDto } from 'src/app/models/licenceDto';
import { LicenceOptionsService } from 'src/app/modules/shared/services/licence-options.service';
import { Client } from 'src/app/models/client';
import { TranslateService } from '@ngx-translate/core';

interface AddLicenceDialogData {
  licenceData: LicenceDto;
  licenceOptionData: OptionFormDtoUpperCase[];
}

@Component({
  selector: 'app-dialog-add-licence',
  templateUrl: './dialog-add-licence.component.html',
  styleUrls: ['./dialog-add-licence.component.scss'],
})
export class DialogAddLicenceComponent implements OnInit {
  guid: string = '';

  clientSelectForm: FormGroup;
  customerSelectForm: FormGroup;
  licenceForm: FormGroup;
  optionsForm: FormGroup;

  initialCustomer: CustomerItemDto | undefined;
  initialCustomerId: number | undefined;
  initialClient: Client | undefined;
  initialClientId: number | undefined;
  initialLicence: LicenceDto | undefined;
  initialLicenceOptions: OptionFormDtoUpperCase[] | undefined;

  @ViewChild(MatStepper) stepper!: MatStepper;

  constructor(
    public dialogService: DialogService,
    public dataService: DataService,
    private dialogFormService: DialogFormService,
    public dialogRef: MatDialogRef<DialogAddLicenceComponent>,
    protected shareClientId: ShareClientId,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AddLicenceDialogData
  ) {
    translate.currentLoader
    this.clientSelectForm = this.dialogFormService.newCompanySelectForm();
    this.customerSelectForm = this.dialogFormService.newCustomerSelectForm();
    this.licenceForm = this.dialogFormService.newLicenceForm();
    this.optionsForm = this.dialogFormService.newOptionsForm();
  }

  ngOnInit(): void {
    if (this.data != null) {
      this.dialogService
        .getSelectedCustomer(this.data.licenceData.customer_id)
        .subscribe((customer) => {
          let initialObjects: OptionFormDtoUpperCase[] = this.data
            .licenceOptionData as OptionFormDtoUpperCase[];

          let uniqueObjects = initialObjects.filter(
            (item, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.OptionTypeID === item.OptionTypeID &&
                  t.OptionVersionID === item.OptionVersionID &&
                  t.Value === item.Value &&
                  t.StartDate === item.StartDate &&
                  t.EndDate === item.EndDate
              )
          );


          this.dialogService.getSelectedClient(customer.client.id).subscribe((client) => {
            this.clientSelectForm.get("id")?.setValue(client.company.id)
            this.shareClientId.currentClientObj = client
          })

          this.customerSelectForm.get("id")?.setValue(customer.company.id)

          this.guid = this.data.licenceData.guid;
          this.initialCustomer = customer;
          this.shareClientId.currentCustomerObj = customer;
          this.initialClient = customer.client;
          this.initialLicence = this.data.licenceData;
          this.initialCustomerId = customer.company.id;
          this.initialClientId = customer.client.id;
          this.initialLicenceOptions = uniqueObjects;
          this.shareClientId.tmpDataList = uniqueObjects;
        });
    }
  }

  save() {
    this.dialogFormService
      .saveAddLicenceForm(
        this.shareClientId.currentClientObj,
        this.shareClientId.currentCustomerObj,
        this.shareClientId.currentLicence,
        this.shareClientId.tmpDataList,
        this.guid
      )
      .subscribe((result) => {
        if (result.success) {
          this.dialogRef.close(result.success);
        }
      });
  }

  get currentStepIndex(): number {
    return this.stepper ? this.stepper.selectedIndex : -1;
  }

  get customerRequest() {
    return this.dialogService.getCustomerList(
      this.clientSelectForm.get('id')?.value
    );
  }

  onStepChange(event: number) {
    this.cdr.detectChanges();
  }

  resetData() {
    console.log('Data Reset');

    this.shareClientId.currentClientObj = {} as CompanyItemDto;
    this.shareClientId.currentCustomerObj = {} as CustomerItemDto;
    this.shareClientId.currentLicence = {} as LicenceFormDto;
    this.shareClientId.tmpDataList = [];
    this.shareClientId.tmpDataOptions = {} as OptionFormDto;

    this.shareClientId.resetClient();
    this.shareClientId.resetTmpData();
  }
}
