<form [formGroup]="form">
  <mat-form-field class="w-full">
    <mat-label>{{"Client" | translate}}</mat-label>
    <mat-select formControlName="id">
      @for (client of clients; track $index) {
      <mat-option [value]="client.company.id" >{{ client.company.name }}</mat-option>
      }
    </mat-select>
    <button mat-icon-button class="undoButton" *ngIf="form.get('id')?.dirty && form.get('id')?.value != initialClient" (click)="undoChanges('id', initialClient); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
  </mat-form-field>
</form>

<!-- <div *ngIf="client != null && client.company != undefined && client?.company?.name != 'UNKNOWN LICENCES'" class="information">
  <div><strong>{{"Company" | translate}} {{"Address" | translate}}:</strong></div>
  <div>{{client.company.name}}</div>
  <div>{{client.company.street}}</div>
  <div>{{client.company.zipcode}} {{ client.company.city }}</div>
  <div>{{client.company.country}}</div>
</div> -->

<div *ngIf="client != null && client.contact != undefined" class="information">
  <div><strong>{{"Contact" | translate}} Information:</strong></div>
  <div>{{client.contact.firstname }} {{client.contact.lastname}}</div>
  <div>{{client.contact.email}}</div>
  <div>{{client.contact.handy}}</div>
  <div>{{client.contact.phone}}</div>
</div>

<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
