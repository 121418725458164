<app-header></app-header>

<form [formGroup]="loginForm" (submit)="login()">
  <div class="rounded w-96 shadow-md mx-auto mt-32 bg-white">
    <div class="login-header h-52 pl-52 text-xl pt-20 rounded-t">
      <span class="vimacc font-bold">vima<span class="vimacc-cc font-bold">cc</span></span><span
        class="text-xs align-top">®</span><br />
      <span class="vimacc-ol">Online Licensing</span>
    </div>
    <div class="w-96 pl-16 pr-16 pt-16">
      <mat-form-field class="w-64">
        <mat-label>{{ "login.username" | translate }}</mat-label>
        <input matInput placeholder="" formControlName="username" />
      </mat-form-field>
    </div>
    <div class="w-96 pl-16 pr-16">
      <!-- password -->
      <mat-form-field class="w-64">
        <mat-label>{{ "login.password" | translate }}</mat-label>
        <input matInput type="password" placeholder="" formControlName="password" autocomplete="false" />
      </mat-form-field>
      <br/>
      <span class="text-xs pb-2">
        <a [routerLink]="['/reset']" class="text-gray-400">
          {{ "login.forgotpassword" | translate }}
        </a>
      </span>
    </div>
    <div class="flex place-content-center pl-16 pr-16 pb-16 pt-8">
      <button type="submit" mat-flat-button [disabled]="!loginForm.valid || loading" color="primary" class="w-full"
        (click)="login()">
        {{ "login.submit" | translate }}
      </button>
    </div>
  </div>
</form>

<app-footer></app-footer>
