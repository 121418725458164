import { ShareClientId } from './../../services/data/companies.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import {
  CustomerItemDto,
  CustomerListItemDto,
} from 'src/app/models/dialog-dto/customer-list-item.dto';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { Company } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dialog-customer-select',
  templateUrl: './dialog-customer-select.component.html',
  styleUrl: './dialog-customer-select.component.scss',
})
export class DialogCustomerSelectComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  clientForm?: FormGroup;
  @Input()
  currentCustomer?: number | string | undefined;
  @Input()
  selectedClient?: number = 0;
  @Input()
  customerInformation?: CustomerItemDto = {} as CustomerItemDto | undefined;

  customers: CustomerListItemDto[] = [];
  currentCustomerId: number = 0;

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    private dataService: DataService,
    private shareClientId: ShareClientId
  ) {
    this.form = dialogFormService.newCustomerSelectForm();
  }

  async ngOnInit(): Promise<void> {
    await new Promise((r) => setTimeout(r, 500));
    if (this.selectedClient) {
      this.dialogService
        .getCustomerList(this.selectedClient as number)
        .subscribe((list) => {
          this.customers.push(...list);
        });
    }

    this.clientForm?.valueChanges.subscribe((client) => {
      this.customers = []
      this.dialogService.getClientByCompanyId(client.id).subscribe((comp) => {
        this.dialogService.getCustomerList(comp.client_id).subscribe((list) => {
          this.customers.push(...list)
        })
      })

    })

    this.form.valueChanges.subscribe((customer) => {
      if (this.form.valid) {
        this.dialogService
          .getSelectedCustomerByCustomerId(customer.id)
          .subscribe((customer) => {
            this.customerInformation = customer;
            this.shareClientId.currentCustomerObj = customer;
          });
      }
    });
  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue("")
    }
  }
}
