import { AddCustomerDto } from 'src/app/models/dialog-dto/add-customer.dto';
import { LicenceType } from 'src/app/models/licence-type';
import { SetLicenceOptionsDto } from './../models/dialog-dto/add-licence.dto';
import { OptionType } from 'src/app/models/option-type';
import { OptionVersion } from 'src/app/models/option-version';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  Form,
  FormControl,
  FormControlName,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AddClientDto } from '../models/dialog-dto/add-client.dto';
import { RestService } from './rest.service';
import { ResponseDto } from '../models/dto/response';
import { AddLicenceDto } from '../models/dialog-dto/add-licence.dto';
import { CustomerItemDto } from '../models/dialog-dto/customer-list-item.dto';
import { CompanyItemDto } from '../models/dialog-dto/company-list-item.dto';
import { LicenceDto } from '../models/licenceDto';
import { LicenceFormDto } from '../models/dialog-dto/licence-form.dto';
import { OptionsFormDto } from '../models/dialog-dto/options-form.dto';
import {
  OptionFormDto,
  OptionFormDtoUpperCase,
} from '../models/dialog-dto/option-form.dto';
import { DataService } from './data.service';
import { DialogService } from './dialog.service';
import { UpdateUserDto } from '../models/dialog-dto/update-user.dto';
import { SaveUserDto, Tag } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class DialogFormService {
  constructor(
    private _restService: RestService,
    private dataService: DataService,
    private dialogService: DialogService
  ) {}

  saveEditUser(
    username: string,
    e_mail: string,
    firstname: string,
    lastname: string,
    guid: string
  ) {
    const dto: UpdateUserDto = {
      username: username,
      e_mail: e_mail,
      firstname: firstname,
      lastname: lastname,
      guid: guid,
    };

    return this._restService.put<ResponseDto>(
      `/auth/updateuser/${dto.guid}`,
      dto
    );
  }

  saveAddClientForm(
    companyForm: FormGroup,
    contactForm: FormGroup,
    selectedTags: Tag[],
    guid?: string
  ) {
    interface AddClientDtoWTags extends AddClientDto {
      tags: Tag[]
    }
    const dto: AddClientDtoWTags = {
      company: {
        id: parseInt(companyForm.get('id')?.value) ?? undefined,
        name: companyForm.get('name')?.value ?? '',
        country: companyForm.get('country')?.value ?? '',
        city: companyForm.get('city')?.value ?? '',
        zipcode: companyForm.get('zipcode')?.value ?? '',
        street: companyForm.get('street')?.value ?? '',
      },
      contact: {
        id: parseInt(contactForm.get('id')?.value) ?? undefined,
        email: contactForm.get('email')?.value ?? '',
        firstname: contactForm.get('firstname')?.value ?? '',
        lastname: contactForm.get('lastname')?.value ?? '',
        phone: contactForm.get('phone')?.value ?? '',
        handy: contactForm.get('handy')?.value ?? '',
      },
      tags: selectedTags
    };

    // if we have a guid, we update an existing client
    if (guid && guid != '') {
      return this._restService.put<ResponseDto>(`/clients/${guid}`, dto);
    } else {
      console.log(dto.tags);

      return this._restService.post<ResponseDto>('/clients', dto);
    }
  }

  confirmDeleteClientForm(guid: string) {
    return this._restService.delete<ResponseDto>(`/clients/${guid}`);
  }

  saveAddCustomerForm(
    clientId: number,
    // clientSelect: FormGroup,
    companyForm: FormGroup,
    contactForm: FormGroup,
    selectedTags: Tag[],
    guid?: string
  ) {
    interface AddCustomerDtoWTags extends AddCustomerDto{
      tags: Tag[]
    }
    // convert form to dto
    const dto: AddCustomerDtoWTags = {
      client: {
        id: clientId ?? 0,
      },
      company: {
        name: companyForm.get('name')?.value ?? '',
        country: companyForm.get('country')?.value ?? '',
        city: companyForm.get('city')?.value ?? '',
        zipcode: companyForm.get('zipcode')?.value ?? '',
        street: companyForm.get('street')?.value ?? '',
      },
      contact: {
        email: contactForm.get('email')?.value ?? '',
        firstname: contactForm.get('firstname')?.value ?? '',
        lastname: contactForm.get('lastname')?.value ?? '',
        phone: contactForm.get('phone')?.value ?? '',
        handy: contactForm.get('handy')?.value ?? '',
      },
      tags: selectedTags
    };

    // if we have a guid, we update an existing customer
    if (guid && guid != '') {
      return this._restService.put<ResponseDto>(`/customers/${guid}`, dto);
    } else {
      return this._restService.post<ResponseDto>('/customers', dto);
    }
  }

  confirmDeleteCustomerForm(guid: string) {
    return this._restService.delete<ResponseDto>(`/customers/${guid}`);
  }

  confirmDeleteUserForm(guid: string) {
    return this._restService.delete<ResponseDto>(`/auth/user/${guid}`);
  }

  saveAddTag(tag: Tag) {
    return this._restService.post<ResponseDto>('/auth/new/tag', tag)
  }

  UpdateTag(tag: Tag) {
    return this._restService.put<ResponseDto>('/auth/update/tag', tag)
  }

  RemoveTag(guid: string) {
    return this._restService.delete<ResponseDto>(`/auth/delete/tag/${guid}`)
  }

  saveAddLicenceType(type: LicenceType) {
    return this._restService.post<ResponseDto>('/licences/licencetype', type);
  }

  saveAddLicenceOption(option: OptionType) {
    return this._restService.post<ResponseDto>(
      '/licences/licenceoption',
      option
    );
  }

  deleteAddLicenceType(id: number) {
    return this._restService.delete<ResponseDto>(`/licences/licencetype/${id}`);
  }

  deleteAddLicenceOption(id: number) {
    return this._restService.delete<ResponseDto>(
      `/licences/licenceoption/${id}`
    );
  }

  editAddLicenceType(type: LicenceType, id: number) {
    return this._restService.put<ResponseDto>(
      `/licences/licencetype/${id}`,
      type
    );
  }

  editAddLicenceOption(option: OptionType, id: number) {
    return this._restService.put<ResponseDto>(
      `/licences/licenceoption/${id}`,
      option
    );
  }

  saveAddLicenceForm(
    clientSelect: CompanyItemDto,
    customerSelect: CustomerItemDto,
    licenceForm: LicenceFormDto,
    optionsForm: OptionFormDtoUpperCase[],
    guid?: string
  ) {
    // convert form to dto
    const dto: AddLicenceDto = {
      client: {
        id: clientSelect.company.id,
      },
      customer: {
        company: customerSelect.company.name,
        country: customerSelect.company.country,
        city: customerSelect.company.city,
        zipcode: customerSelect.company.zipcode,
        street: customerSelect.company.street,

        firstname: customerSelect.contact.firstname,
        lastname: customerSelect.contact.lastname,
        email: customerSelect.contact.email,
        phone: customerSelect.contact.phone,

        id: customerSelect.client.id,
      },
      licence: {
        vrs: licenceForm.vrs,
        projectName: licenceForm.projectName,
        type: licenceForm.type,
        status: licenceForm.status,
      },
      options: [],
    };

    optionsForm.forEach((option) => {
      dto.options.push({
        ID: option.ID,
        GUID: option.GUID,
        OptionTypeID: option.OptionTypeID,
        OptionVersionID: option.OptionVersionID,
        Value: option.Value,
        StartDate: option.StartDate + 'T00:00:00Z',
        EndDate: option.EndDate + 'T00:00:00Z',
      } as OptionFormDtoUpperCase);
    });

    // if we have a guid, we update an existing customer
    if (guid && guid != '') {
      return this._restService.put<ResponseDto>(`/licences/${guid}`, dto);
    } else {
      return this._restService.post<ResponseDto>('/licences', dto);
    }
  }

  saveUser(dto: SaveUserDto, guid: string) {
    if (guid != '') {
      return this._restService.put<ResponseDto>(`/auth/user/edit/${guid}`, dto);
    }
    return this._restService.post<ResponseDto>(`/auth/user`, dto);
  }

  saveSetOptionsForm(
    licence_id: string,
    optionsForm: OptionFormDtoUpperCase[]
  ) {
    const dto: SetLicenceOptionsDto = {
      LicenceGuid: licence_id,
      options: [],
    };

    optionsForm.forEach((option) => {
      dto.options.push({
        ID: option.ID,
        GUID: option.GUID,
        OptionTypeID: option.OptionTypeID,
        OptionVersionID: option.OptionVersionID,
        Value: option.Value,
        StartDate: option.StartDate + 'T00:00:00Z',
        EndDate: option.EndDate + 'T00:00:00Z',
      } as OptionFormDtoUpperCase);
    });

    return this._restService.post<ResponseDto>(
      `/licences/${licence_id}/options`,
      dto
    );
  }

  confirmDeleteLicenceForm(guid: string) {
    return this._restService.delete<ResponseDto>(`/licences/${guid}`);
  }

  confirmChangePassword(password: string, newPassword: string, guid: string) {
    const dto = {
      password: password,
      newPassword: newPassword,
      guid: guid,
    };
    return this._restService.put<ResponseDto>('/auth/changePassword', dto);
  }

  newExportCSVForm() {
    return new FormGroup({
      violationStart: new FormControl(""),
      violationEnd: new FormControl(""),
      usageStart: new FormControl(""),
      usageEnd: new FormControl(""),
      order: new FormControl(""),
      customerId: new FormControl(''),
      licenceId: new FormControl(''),
    });
  }

  newProfileForm() {
    return new FormGroup({
      id: new FormControl(''),
      username: new FormControl(''),
      email: new FormControl(''),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      company: new FormControl(''),
      customer: new FormControl(''),
    });
  }

  newChangePasswordForm() {
    return new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required),
    });
  }

  newLicenceTypeForm() {
    return new FormGroup({
      active: new FormControl(false),
      name: new FormControl('', Validators.required),
      desc: new FormControl('', Validators.required),
      descEn: new FormControl('', Validators.required),
    });
  }

  newLicenceOptionTypeForm() {
    return new FormGroup({
      active: new FormControl(false),
      name: new FormControl('', Validators.required),
      optionType: new FormControl('', Validators.required),
      icon: new FormControl('', Validators.required),
    });
  }

  newTagForm() {
    return new FormGroup({
      active: new FormControl(false),
      name: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
      id: new FormControl(0)
    });
  }

  newCompanyForm() {
    return new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      country: new FormControl(''),
      city: new FormControl(''),
      zipcode: new FormControl(''),
      street: new FormControl(''),
      tags: new FormControl(''),
    });
  }

  newContactForm() {
    return new FormGroup({
      id: new FormControl(undefined),
      email: new FormControl(''),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      phone: new FormControl(''),
      handy: new FormControl(''),
      active: new FormControl(false),
    });
  }

  newUserForm() {
    return new FormGroup({
      username: new FormControl(''),
      email: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      tags: new FormControl(''),
      active: new FormControl(false),
      contact: new FormControl(''),
      role: new FormControl(''),
    });
  }

  newCompanySelectForm() {
    return new FormGroup({
      id: new FormControl('', Validators.required),
    });
  }

  newCustomerSelectForm() {
    return new FormGroup({
      id: new FormControl('', Validators.required),
    });
  }

  newLicenceSelectForm() {
    return new FormGroup({
      id: new FormControl('', Validators.required),
    });
  }

  newLicenceForm() {
    return new FormGroup({
      vrs: new FormControl('', [Validators.required, minLengthValidator(16)]),
      projectName: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
    });
  }

  newOptionsForm() {
    return new FormGroup(
      {
        OptionTypeID: new FormControl(''),
        OptionVersionID: new FormControl(''),
        Value: new FormControl(''),
        StartDate: new FormControl(''),
        EndDate: new FormControl(''),
      },
      {}
    );
  }
}

const minLengthValidator = (minLength: number) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    if (value.length >= minLength) {
      return null; // No error
    } else {
      return {
        minLength: { requiredLength: minLength, actualLength: value.length },
      };
    }
  };
};
