<h1 mat-dialog-title class="dialog-header">{{"Edit Options" | translate}}</h1>

<mat-dialog-content class="mat-typography">
  <mat-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="optionsForm">
      <ng-template matStepLabel>{{"Licence Options" | translate}}</ng-template>

      <app-dialog-options-form [form]="optionsForm"></app-dialog-options-form>

      <div class="float-right">
        <button mat-button mat-dialog-close (click)="resetData()">{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
          {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2">{{"Next" | translate}}</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{"Confirmation" | translate}}</ng-template>
      <app-dialog-verify-licence></app-dialog-verify-licence>

      <div class="float-right">
        <button mat-button mat-dialog-close (click)="resetData()">{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
          Back
        </button>
        <button
          mat-flat-button
          cdkFocusInitial
          color="primary"
          class="ml-2"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
