<h1 mat-dialog-title class="dialog-header" *ngIf="!guid">{{ "dialog.adduser" | translate }}</h1>
<h1 mat-dialog-title class="dialog-header" *ngIf="guid">{{ "dialog.edituser" | translate }}</h1>

<mat-dialog-content class="mat-typography">
  <mat-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="form">
      <ng-template matStepLabel>{{"UserData" | translate}}</ng-template>
      <h3>{{"UserData" | translate}}</h3>
      <form [formGroup]="form">
        <mat-form-field class="w-full">
          <mat-label>{{"E-mail"| translate}}</mat-label>
          <input matInput placeholder="" formControlName="email" />
          <button mat-icon-button class="undoButton" *ngIf="form.get('email')?.dirty && form.get('email')?.value != ''" (click)="undoChanges('email', ''); $event.stopPropagation()">
            <mat-icon>undo</mat-icon>
          </button>
        </mat-form-field>
        <div class="w-full flex justify-between gap-8">
          <mat-form-field class="w-full">
            <mat-label>{{"Firstname"| translate}}</mat-label>
            <input matInput placeholder="" formControlName="firstname" />
            <button mat-icon-button class="undoButton" *ngIf="form.get('firstname')?.dirty && form.get('firstname')?.value != ''" (click)="undoChanges('firstname', ''); $event.stopPropagation()">
              <mat-icon>undo</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>{{"Lastname"| translate}}</mat-label>
            <input matInput placeholder="" formControlName="lastname" />
            <button mat-icon-button class="undoButton" *ngIf="form.get('lastname')?.dirty && form.get('lastname')?.value != ''" (click)="undoChanges('lastname', ''); $event.stopPropagation()">
              <mat-icon>undo</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="w-full flex justify-between gap-8 mb-4">
          <mat-form-field class="w-full h-20 max-h-20">
            <mat-label>{{ "Tags" | translate }}</mat-label>
            <mat-select formControlName="tags">
              @for (tag of allTags; track $index) {
              <mat-option [value]="tag.Name" (click)="appendTag(tag)">{{
                tag.Name
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <div class="w-full flex flex-wrap tag-container">
              @for (tag of selectedTags; track $index) {
                <span class="tag" [style]="'background-color:' + tag.Color"><span class="brightness-50 saturate-200" [style]="'color: ' + tag.Color">{{tag.Name}}</span><span style="margin: 0 -.5rem 0 .75rem" class="brightness-50 saturate-200 cursor-pointer" [style]="'color: ' + tag.Color" (click)="removeTag(tag)">x</span></span>
              }
          </div>
        </div>
        <div class="w-full flex justify-between gap-8">
          <mat-form-field class="w-full h-20 max-h-20">
            <mat-label>{{ "Role" | translate }}</mat-label>
            <mat-select formControlName="role">
              @for (role of allRoles; track $index) {
              <mat-option [value]="role.ID" (click)="setRole(role.Name)">{{
                role.Name
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-full h-20 max-h-20">
            <mat-label>{{ "Contacts" | translate }}</mat-label>
            <mat-select formControlName="contact">
              @for (contact of allContacts; track $index) {
              <mat-option [value]="contact.ID" (click)="setContact(contact.Firstname , contact.Lastname)">{{contact.Firstname}} {{contact.Lastname}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
          {{"Next" | translate}}
        </button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{"Confirmation" | translate}}</ng-template>
      <h3>{{"Confirmation" | translate}}</h3>
      <ng-container>
          <div class="ml-4">
            {{"Name" | translate}}:
            {{form.get("firstname")?.value}}
            {{form.get("lastname")?.value}}
          </div>
          <div class="ml-4">
            {{"E-mail" | translate}}:
            {{form.get("email")?.value}}
          </div>
          <div class="ml-4">
            {{"Role" | translate}}:
            {{selectedRole}}
          </div>
          <div class="ml-4">
            {{"Contact" | translate}}:
            {{selectedContact}}
          </div>
          <div class="ml-4">
            {{"Tags" | translate}}:
            @for (tag of selectedTags; track $index) {
              <span class="tag" [style]="'background-color:' + tag.Color"><span class="brightness-50 saturate-200" [style]="'color: ' + tag.Color">{{tag.Name}}</span></span>
            }
          </div>
      </ng-container>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          cdkFocusInitial
          color="primary"
          class="ml-2"
          (click)="save()"
        >
        {{"Save" | translate}}
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
