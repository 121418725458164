<h1 mat-dialog-title class="dialog-header">{{"deleteCustomer" |translate}}</h1>

<mat-dialog-content class="mat-typography">
  <h3>{{"deleteTextCustomer" | translate}}</h3>
  <h4>{{"User" | translate}}</h4>
  <ng-container>
    <div class="ml-4">
      {{"Name" | translate}}:
      {{user.Firstname}}
      {{user.Lastname}}
    </div>
    <div class="ml-4">
      {{"E-mail" | translate}}:
      {{user.EMail}}
    </div>
    <div class="ml-4">
      {{"Role" | translate}}:
      {{selectedRole.Name}}
    </div>
    <div class="ml-4">
      {{"Contact" | translate}}:
      {{selectedContact.Firstname}}
      {{selectedContact.Lastname}}
    </div>
    <div class="ml-4" *ngIf="selectedTags.length != 0">
      {{"Tags" | translate}}:
      @for (tag of selectedTags; track $index) {
        <span class="tag" [style]="'background-color:' + tag.Color"><span class="brightness-50 saturate-200" [style]="'color: ' + tag.Color">{{tag.Name}}</span></span>
      }
    </div>
</ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
  <button mat-flat-button cdkFocusInitial (click)="confirm()" color="primary">{{"Confirm" | translate}}</button>
</mat-dialog-actions>
