import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LicenceStatusComponent } from './components/licence-status/licence-status.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { UserManagementPanelComponent } from './components/user-management-panel/user-management-panel.component';
import { RouterLink } from '@angular/router';
import { SuccessComponent } from './components/success/success.component';
@NgModule({
  exports: [
    CommonModule,
    TranslateModule,
    HeaderComponent,
    FooterComponent,
    LicenceStatusComponent,
    UserManagementPanelComponent,
    SuccessComponent,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LicenceStatusComponent,
    UserManagementPanelComponent,
    SuccessComponent,
  ],
  imports: [
    RouterLink,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatTooltipModule,
  ],
})
export class SharedModule {}
