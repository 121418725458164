import { ShareClientId } from 'src/app/services/data/companies.service';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { OptionType } from 'src/app/models/option-type';
import { OptionVersion } from 'src/app/models/option-version';

@Component({
  selector: 'app-dialog-options-form',
  templateUrl: './dialog-options-form.component.html',
  styleUrls: ['./dialog-options-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogOptionsFormComponent implements OnInit {
  @Input()
  form: FormGroup;

  optionTypes: OptionType[] = [];
  optionVersions: OptionVersion[] = [];
  checkIfEarlier = true;
  checkIfLogicalDate = true;
  checkIfListChanges: number[] = [0, 0];

  constructor(
    private dialogFormService: DialogFormService,
    public dataService: DataService,
    private shareClientId: ShareClientId,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.dialogFormService.newOptionsForm();

    this.dataService.optionTypes.forEach((type) => this.optionTypes.push(type));
    this.dataService.optionVersions.forEach((version) =>
      this.optionVersions.push(version)
    );
  }

  addToCurrentList = () => {
    if (
      this.form.get('OptionTypeID')?.value != '' &&
      this.form.get('OptionVersionID')?.value != '' &&
      this.form.get('Value')?.value != '' &&
      this.form.get('StartDate')?.value != '' &&
      this.form.get('EndDate')?.value != '' &&
      this.checkIfEarlier
    ) {
      this.shareClientId.setTmpDataOptions(this.form.value);

      this.form.get('Value')?.setErrors(null);
      this.form.get('StartDate')?.setErrors(null);
      this.form.get('EndDate')?.setErrors(null);
      this.form.get('OptionTypeID')?.setErrors(null);
      this.form.get('OptionVersionID')?.setErrors(null);
    } else {
      if (this.form.get('Value')?.value == '') {
        this.form.get('Value')?.setErrors({ forcedError: true });
      }
      if (this.form.get('StartDate')?.value == '') {
        this.form.get('StartDate')?.setErrors({ forcedError: true });
      }
      if (this.form.get('EndDate')?.value == '') {
        this.form.get('EndDate')?.setErrors({ forcedError: true });
      }
      if (this.form.get('OptionTypeID')?.value == '') {
        this.form.get('OptionTypeID')?.setErrors({ forcedError: true });
      }
      if (this.form.get('OptionVersionID')?.value == '') {
        this.form.get('OptionVersionID')?.setErrors({ forcedError: true });
      }
    }
    this.cdr.detectChanges();
  };

  isEarlier(start: string, end: string): boolean {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Check if both dates are valid
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      // Handle invalid dates, could be false or throw an error depending on use case
      return false;
    }
    this.checkIfEarlier = startDate < endDate;
    this.checkIfLogicalDate =
      2000 <= startDate.getFullYear() &&
      startDate.getFullYear() < 2100 &&
      2000 <= endDate.getFullYear() &&
      endDate.getFullYear() < 2100;

    return startDate < endDate;
  }

  get startDate() {
    return this.form.get('StartDate');
  }

  get endDate() {
    return this.form.get('EndDate');
  }

  ngOnInit(): void {
    this.checkIfListChanges[1] = this.shareClientId.tmpDataList.length;
    const formFields = [
      'OptionTypeID',
      'OptionVersionID',
      'Value',
      'StartDate',
      'EndDate',
    ];

    this.form.valueChanges.subscribe((val) => {
      this.checkIfListChanges[0] = this.checkIfListChanges[1];
      this.checkIfListChanges[1] = this.shareClientId.tmpDataList.length;

      this.isEarlier(val.StartDate, val.EndDate);
    });
  }
}
