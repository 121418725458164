import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss',
})

export class SetPasswordComponent {
  id: string = ""
  success: boolean = false
  matchingError: boolean = false

  setPasswordConfirmForm: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', Validators.required),
    confirmPassword: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private _translateService: TranslateService,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {
    this.route.paramMap.subscribe(param => {this.id = param.get("id") as string});
  }

  reset() {
    if (this.setPasswordConfirmForm.get("password")?.value != this.setPasswordConfirmForm.get("confirmPassword")?.value) {
      this.matchingError = true
      return
    }
    this.dataService.resetPasswordConfirm(this.setPasswordConfirmForm.get("password")?.value, this.id).subscribe(
      response => {
        console.log('Success:', response);
        this.success = true
        this.matchingError = false
      },
      error => {
        console.error('Error:', error);
      }
    );
  }
}
