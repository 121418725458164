<mat-toolbar class="fixed" [ngClass]="{ 'shadow-lg': isAuthenticated }">
  <div class="container inner-toolbar">
    <div class="title" (click)="toOverview()" style="cursor: pointer;">
      <span *ngIf="isAuthenticated">
        <span class="vimacc">vima<span class="vimacc-cc">cc</span></span
        ><span class="text-xs align-top">®</span> <span class="vimacc-ol">Online Licensing</span>
      </span>
    </div>
    <div class="spacer"></div>
    <div class="navi flex space-x-4">
      <span *ngIf="isAuthenticated" class="headerIcons">
        <button mat-icon-button extended color="ghost" (click)="toOverview()" id="tableIcon">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button extended color="ghost" (click)="toAdministration()" *ngIf="isAdmin" id="settingsIcon">
          <mat-icon style="font-size: 27px; margin-top: -1px;">admin_panel_settings</mat-icon>
        </button>
      </span>
    </div>
    <div class="spacer"></div>
    <div class="right-side">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>account_box</mat-icon>
      </button>
      <span class="h-9 user pl-2 pr-2">
        {{ user.Firstname }} {{ user.Lastname }}
      </span>
      <button
        mat-icon-button
        extended
        color="ghost"
        [matMenuTriggerFor]="langMenu"
      >
        <mat-icon>language</mat-icon>
      </button>
            <!-- <button
        mat-icon-button
        extended
        color="ghost"
        (click)="Test()"
      >
        <mat-icon>add</mat-icon>
      </button> -->
      <!--
        <button mat-icon-button extended color="ghost" (click)="toggleDarkMode()">
          <mat-icon>brightness_6</mat-icon>
        </button>
      -->
      </div>
  </div>
</mat-toolbar>

<mat-menu #menu="matMenu" xPosition="before">
  <!--
  <button mat-menu-item>
    <mat-icon>password</mat-icon>
    <span>Change Password</span>
  </button>
-->
  <button mat-menu-item (click)="profile()">
    <mat-icon>edit</mat-icon>
    <span>{{"EditProfile" | translate}}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{"Logout" | translate}}</span>
  </button>
</mat-menu>

<mat-menu #langMenu="matMenu">
  <button
    mat-menu-item
    (click)="changeLanguage(lang)"
    *ngFor="let lang of languages"
  >
    {{ "header.language." + lang | translate }}
  </button>
</mat-menu>

<div style="width: 100%; min-height: 4rem;"></div>
