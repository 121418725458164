import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment.local";

/**
 * HttpLoaderFactory is a wrapper function to load translation files from the frontend server
 * the translation files are located in assets/i18n
 *
 * @param http
 * @returns
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.assets.uri,
    environment.assets.ext
  );
}
