<h1 mat-dialog-title class="dialog-header" *ngIf="data.viewObject == 'client'">
  {{ "Client Information" | translate }}
</h1>
<h1 mat-dialog-title class="dialog-header"  *ngIf="data.viewObject == 'customer'">
  {{ "Customer Information" | translate }}
</h1>
<h1 mat-dialog-title class="dialog-header"  *ngIf="data.viewObject == 'licence'">
  {{ "Licence Information" | translate }}
</h1>
<mat-dialog-content class="mat-typography">
  <mat-divider></mat-divider>
  <ng-container *ngIf="data.viewObject == 'client' || data.viewObject=='customer'">
    <div class="m-8">
      <h4>{{ "Client" | translate }}:</h4>
      <div *ngIf="companyData.company" class="mb-8">
        <p>{{ companyData.company.name }}</p>
        <p>{{ companyData.company.street }}</p>
        <p>{{ companyData.company.zipcode }} {{ companyData.company.city }}</p>
        <p>{{ companyData.company.country }}</p>
      </div>

      <h4>{{ "Contact" | translate }}:</h4>
      <div *ngIf="companyData.contact">
        <p>
          {{ companyData.contact.firstname }} {{ companyData.contact.lastname }}
        </p>
        <p>{{ companyData.contact.email }}</p>
        <p>{{ companyData.contact.phone }}</p>
        <p>{{ companyData.contact.handy }}</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data.viewObject=='licence'" >
    <h4>{{ "Licence" | translate }}:</h4>
    <div *ngIf="licenceData">
      <p>Name: {{licenceData.project}} </p>
      <p>VRS: {{licenceData.vrs}} </p>
      <p>Status: {{status.status}} </p>
      <p>Type: {{type.name}} </p>
    </div>
  </ng-container>
  <mat-divider></mat-divider>
  <div class="float-right mt-2">
    <button
      mat-flat-button
      cdkFocusInitial
      matDialogClose
      color="primary"
      class="ml-2"
    >
      {{ "OK" | translate }}
    </button>
  </div>
</mat-dialog-content>
