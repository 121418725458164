import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _messages: Subject<Notification> = new Subject<Notification>();
  constructor() {}

  addMessage(message: Notification) {
    this._messages.next(message);
  }

  get messages() {
    return this._messages.asObservable();
  }
}

export interface Notification {
  text: string;
  options?: MatSnackBarConfig<any>;
}
