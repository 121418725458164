import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DialogAddClientComponent } from '../dialog-add-client/dialog-add-client.component';
import { AddClientDto } from 'src/app/models/dialog-dto/add-client.dto';

interface DeleteClientDialogData {
  guid: string;
}

@Component({
  selector: 'app-dialog-delete-client',
  templateUrl: './dialog-delete-client.component.html',
  styleUrl: './dialog-delete-client.component.scss',
})
export class DialogDeleteClientComponent {
  guid: string = '';
  client: AddClientDto = {
    company: {
      name: '',
      country: '',
      city: '',
      zipcode: '',
      street: '',
    },
    contact: {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      handy: '',
    },
  };

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogAddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteClientDialogData
  ) {
    if (data?.guid && data.guid !== '') {
      this.guid = data.guid;
      this.dialogService
        .getEditClientDataGuid(data.guid)
        .subscribe((data) => (this.client = data));
    }
  }

  confirm() {
    this.dialogFormService.confirmDeleteClientForm(this.guid).subscribe(result => {
      if (result.success) {
        this.dialogRef.close(result.success)
      }
    });
  }
}
