<form [formGroup]="form">
  <mat-form-field class="w-full">
    <mat-label>{{"Customer" | translate}}</mat-label>
    <mat-select formControlName="id">
      @for (client of customers; track $index) {
      <mat-option [value]="client.company.id">{{ client.company.name }}</mat-option>
      }
    </mat-select>
    <button mat-icon-button class="undoButton" *ngIf="form.get('id')?.dirty && form.get('id')?.value != currentCustomer" (click)="undoChanges('id', currentCustomer); $event.stopPropagation()">
      <mat-icon>undo</mat-icon>
    </button>
  </mat-form-field>
</form>

<div *ngIf="customerInformation?.company?.name != 'UNKNOWN LICENCES' && customerInformation != null && customerInformation.company != undefined" class="information">
  <div><strong>{{"Company"|translate}} {{"Address"| translate}}:</strong></div>
  <div>{{customerInformation.company.name}}</div>
  <div>{{customerInformation.company.street}}</div>
  <div>{{customerInformation.company.zipcode}} {{ customerInformation.company.city }}</div>
  <div>{{customerInformation.company.country}}</div>
</div>

<div *ngIf="customerInformation?.contact?.id != 0 && customerInformation!= undefined" class="information">
  <div><strong>{{"Contact"|translate}} Information:</strong></div>
  <div>{{customerInformation.contact.firstname }} {{customerInformation.contact.lastname}}</div>
  <div>{{customerInformation.contact.email}}</div>
  <div>{{customerInformation.contact.handy}}</div>
  <div>{{customerInformation.contact.phone}}</div>
</div>

<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
