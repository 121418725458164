export const environment = {
  production: true,
  apiUrl: '/api',
  defaultLanguage: 'de',
  allowedLanguages: ['de', 'en'],
  locales: {
    'de': 'de-DE',
    'en': 'en-GB'
  },
  assets: {
    uri: './assets/i18n/',
    ext: '.json',
  },
  authHeader: 'X-Accellence-Auth',
};
