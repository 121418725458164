import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { NotificationService } from '../services/notification.service';

/**
 * authGuard is a function that is called to check if a user has permissions to a specific url
 *
 * @param route
 * @param state
 * @returns
 */
export const authGuard: CanActivateFn = (route, state) => {
  const _authService: AuthService = inject(AuthService);
  const _router: Router = inject(Router);
  const _notificationService: NotificationService = inject(NotificationService);

  // return true if no roles are set for the route or the authenticated suer has one of the roles
  if (typeof route.data['role'] == 'undefined') {
    return true;
  } else if (_authService.checkRole(route.data['role'])) {
    return true;
  }

  // TODO: Show error message "you dont have access to this function"
  _notificationService.addMessage({text:"Please log in"});

  return _router.parseUrl('/login');
};
