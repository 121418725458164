<h1 mat-dialog-title>{{"deleteCustomer" |translate}}</h1>

<mat-dialog-content class="mat-typography">
  <h3 class="primary">{{"deleteTextCustomer" | translate}}</h3>
  <h4>{{"Company" | translate}}</h4>
  <p>
    {{ customer.company.name }}<br />
    {{ customer.company.street }}<br />
    {{ customer.company.zipcode}} {{ customer.company.city }}<br />
    {{ customer.company.country }}
  </p>
  <h4>Contact</h4>
  <p>
    {{ customer.contact.firstname }}
    {{ customer.contact.lastname }}<br />
    {{ customer.contact.email }}<br />
    {{ customer.contact.phone }}<br />
    {{ customer.contact.handy }}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
  <button mat-flat-button cdkFocusInitial (click)="confirm()" color="primary">{{"Confirm" | translate}}</button>
</mat-dialog-actions>
