import { CompanyItemDto } from './../../models/dialog-dto/company-list-item.dto';
import {
  CompaniesService,
  ShareClientId,
} from './../../services/data/companies.service';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormGroup } from '@angular/forms';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { Company } from 'src/app/models/company';
import { DialogService } from 'src/app/services/dialog.service';
import { CompanyListItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { CompanySelectDto } from 'src/app/models/dialog-dto/company-select.dto';
import { DataService } from 'src/app/services/data.service';
import { LicenceDto, LicenceDtoUpper } from 'src/app/models/licenceDto';
import { LicenceFormDto } from 'src/app/models/dialog-dto/licence-form.dto';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';

@Component({
  selector: 'app-dialog-licence-select',
  templateUrl: './dialog-licence-select.component.html',
  styleUrl: './dialog-licence-select.component.scss',
})
export class DialogLicenceSelectComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  customerForm?: FormGroup;

  licences: LicenceDtoUpper[] | undefined;
  licence: LicenceDtoUpper | undefined;
  customer: CustomerItemDto | undefined;
  company: CompanyItemDto | undefined;
  initiaLicence: number | string = 0

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    private dataService: DataService,
    private shareClientId: ShareClientId
  ) {
    this.form = this.dialogFormService.newLicenceSelectForm();
  }
  async ngOnInit(): Promise<void> {
    await new Promise((r) => setTimeout(r, 500));
    this.customerForm?.valueChanges.subscribe((customer) => {
      this.licences = [];
      this.dialogService
        .getLicenceListByCustomer(customer.id as number)
        .subscribe((licences) => {
          this.licences?.push(...licences);
        });
    });

    this.form.valueChanges.subscribe((licence) => {
      if (this.initiaLicence == 0) {
        this.initiaLicence = licence.id
      }
      if (this.form.valid) {
        this.dialogService.getLicence(licence.id).subscribe(licence => {
          this.shareClientId.currentLicenceGuid = licence.GUID
        })
        this.dataService
          .getOptionsByLicence(licence.id)
          .subscribe((options) => {
            this.shareClientId.tmpDataList = options;
          });
      }
    });
  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue("")
    }
  }
}
