<div class="verifyGrid" id="verifyGrid" >
  <div *ngIf="company && company != undefined" class="client">
    <h2>{{"Client" | translate}}:</h2>
    <div *ngIf="company.company.name">{{ company.company.name }}</div>
    <div *ngIf="company.contact.firstname && company.contact.lastname">{{ company.contact.firstname }} {{ company.contact.lastname }}</div>
    <div *ngIf="company.contact.email">{{ company.contact.email }}</div>
    <div *ngIf="company.contact.phone">{{ company.contact.phone }}</div>
  </div>
  <div *ngIf="customer && customer != undefined" class="customer">
    <h2>{{"Customer" | translate}}:</h2>
    <div *ngIf="customer.company.name">{{ customer.company.name }}</div>
    <div *ngIf="customer.contact.firstname && customer.contact.lastname">{{ customer.contact.firstname }} {{ customer.contact.lastname }}</div>
    <div *ngIf="customer.contact.email">{{ customer.contact.email }}</div>
    <div *ngIf="customer.contact.phone">{{ customer.contact.phone }}</div>
  </div>
  <div *ngIf="licence && licence != undefined" class="licence">
    <h2>{{"Licence" |translate}}:</h2>
    <div *ngIf="licence.vrs">{{ licence.vrs }}</div>
    <div *ngIf="licence.projectName">{{ licence.projectName }}</div>
    <div *ngIf="licenceType">{{ licenceType.name }}</div>
    <div *ngIf="licenceStatus">{{ licenceStatus.status }}</div>
  </div>
  <div *ngIf="options" class="options">
    <app-mat-table-options [verify]="true"></app-mat-table-options>
  </div>
</div>
