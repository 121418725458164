import { DataService } from 'src/app/services/data.service';
import { ShareClientId } from 'src/app/services/data/companies.service';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogService } from 'src/app/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';

interface guid {
  guid: string
}

@Component({
  selector: 'app-dialog-edit-password',
  templateUrl: './dialog-edit-password.component.html',
  styleUrl: './dialog-edit-password.component.scss'
})
export class DialogEditPasswordComponent {
  @Input()
  form: FormGroup

  constructor(
    public dialogService: DialogService,
    public dataService: DataService,
    private dialogFormService: DialogFormService,
    public dialogRef: MatDialogRef<DialogEditPasswordComponent>,
    protected shareClientId: ShareClientId,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: guid
  ) {
      this.form = this.dialogFormService.newChangePasswordForm()
      this.form.get("confirmNewPassword")?.setValidators(this.passwordMatch())
    }

    passwordMatch(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const newPassword = this.form?.get('newPassword')?.value;
        const confirmNewPassword = control.value;

        return newPassword === confirmNewPassword ? null : { passwordMismatch: true };
      };
    }

    save() {
      this.dialogFormService.confirmChangePassword(this.form.get("currentPassword")?.value, this.form.get("newPassword")?.value, this.data.guid,).subscribe(response => {
        if (response.success) {
          this.dialogRef.close({success: true});
        }
      })
    }
}
