import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';

/**
 * ErrorInterceptor handles errors from requests to the backend
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          switch (error.status) {
            case 401:
              this._notificationService.addMessage({
                text: this._translateService.instant(
                  // TODO: translate key
                  'Authentication failed or session expired. Please log in again!'
                ),
              });

              // something is wrong with the authentication, reset it
              this._authService.reset();

              // navigate to login after reset
              this._router.navigate(['/login']);
              break;
            case 403:
              this._notificationService.addMessage({
                text: this._translateService.instant(
                  // TODO: translate key
                  'You do not have access to this function'
                ),
              });

              // navigate to login after reset
              this._router.navigate(['/login']);
              break;
            case 404:
              this._notificationService.addMessage({
                text: this._translateService.instant(
                  // TODO: translate key
                  'Unknown Function!'
                ),
              });
              break;
            default:
              this._notificationService.addMessage({
                text: this._translateService.instant(
                  // TODO: translate key
                  'There was an unexpected error. Please try again or contact an administrator.'
                ),
              });
          }
        },
      })
    );
  }
}
