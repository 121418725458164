import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Role, SaveUserDto, Tag, UserUpper } from 'src/app/models/user';
import { ContactUpper } from 'src/app/models/contact';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface AddUserDialogData {
  guid: string
}

@Component({
  selector: 'app-dialog-add-user',
  templateUrl: './dialog-add-user.component.html',
  styleUrl: './dialog-add-user.component.scss',
})

export class DialogAddUserComponent implements OnInit {
  guid: string = '';
  form: FormGroup;

  allTags: Tag[] = [];
  allRoles: Role[] = [];
  allContacts: ContactUpper[] = [];

  selectedContact: string = '';
  selectedRole: string = '';
  selectedTags: Tag[] = [];

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    private dataService: DataService,
    private dialogRef: MatDialogRef<DialogAddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddUserDialogData
  ) {
    this.form = this.dialogFormService.newUserForm();

    if(this.data?.guid != undefined && this.data?.guid != "") {
      this.guid = this.data.guid
      this.dataService.getUser(this.data.guid).subscribe(user => {
        const userUpper = user as unknown as UserUpper
        this.form.get("email")?.setValue(userUpper.EMail)
        this.form.get("firstname")?.setValue(userUpper.Firstname)
        this.form.get("lastname")?.setValue(userUpper.Lastname)
        this.form.get("role")?.setValue(userUpper.RoleID)
        this.form.get("contact")?.setValue(userUpper.ContactID)

        this.dataService.getTagsByUser(userUpper.ID).subscribe(tags => {
          this.selectedTags = tags
        })
      })
    }
  }

  ngOnInit(): void {
    this.dataService.getTags().subscribe((tags) => {
      this.allTags = tags;
    });

    this.dataService.getContacts().subscribe((contacts) => {
      this.allContacts = contacts as unknown as ContactUpper[];
    });

    this.dataService.getRoles().subscribe((roles) => {
      this.allRoles = roles;
    });
  }

  save() {
    const dto: SaveUserDto = {
      firstname: this.form.get("firstname")?.value,
      lastname: this.form.get("lastname")?.value,
      email: this.form.get("email")?.value,
      role: this.form.get("role")?.value,
      contact: this.form.get("contact")?.value,
      tags: this.selectedTags
    }

    this.dialogFormService.saveUser(dto, this.guid).subscribe(response => {
      if (response.success) {
        this.dialogRef.close([response.success, dto]);
      }
    })
  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue('');
    }
  }

  appendTag(tag: Tag) {
    let found = false
    this.selectedTags.forEach(t => {
      if(t.ID == tag.ID) {
        found = true
      }
    })
    if(!found) {
      this.selectedTags.push(tag)
    }
  }

  removeTag(tag: Tag) {
    if(this.selectedTags.includes(tag)) {
      this.selectedTags = this.selectedTags.filter(t => t != tag)
    }
  }

  setContact(firstname: string | undefined, lastname: string | undefined) {
    this.selectedContact = firstname + ' ' + lastname;
  }

  setRole(role: string | undefined) {
    this.selectedRole = role as string;
  }
}
