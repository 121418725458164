import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})

export class ResetPasswordComponent {

  requested: boolean = false;

  resetPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private _translateService: TranslateService,
    private dataService: DataService
  ) {}

  reset() {
    this.dataService.resetPassword(this.resetPasswordForm.get('email')?.value).subscribe(val => {
      if (val) {
        this.requested = true
      }
    });
  }
}
