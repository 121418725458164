import {
  OptionFormDto,
  OptionFormDtoUpperCase,
} from 'src/app/models/dialog-dto/option-form.dto';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, forkJoin, map, of, tap } from 'rxjs';
import { OptionVersion } from '../models/option-version';
import { RestService } from './rest.service';
import { OptionType, OptionTypeUpper } from '../models/option-type';
import { LicenceType, LicenceTypeUpper } from '../models/licence-type';
import { LicenceStatus } from '../models/licence-status';
import { Company } from '../models/company';
import { LicenceDto } from '../models/licenceDto';
import { HttpClient } from '@angular/common/http';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { UserDto } from '../models/user-dto';
import { CustomerItemDto } from '../models/dialog-dto/customer-list-item.dto';
import { Contact } from '../models/contact';
import { LanguageService } from './language.service';
import { ExportCSV } from '../models/export-csv';
import { Role, Tag, User, UserUpper } from '../models/user';
import { ResponseDto } from '../models/dto/response';

/**
 * DataService is responsible for loading and holding data used in several places of the app
 *
 * it holds the following data:
 * - companies
 * - licence statuses
 * - licence types
 * - option types
 * - option versions
 */
@Injectable({
  providedIn: 'root',
})
export class DataService {
  /**
   * map to hold all option versions
   */
  private _optionVersions: Map<number, OptionVersion> = new Map<
    number,
    OptionVersion
  >();

  /**
   * map to hold all option types
   */
  private _optionTypes: Map<number, OptionType> = new Map<number, OptionType>();


  private _options: Map<string, OptionFormDto> = new Map<
    string,
    OptionFormDto
  >();

  /**
   * map to hold all option types
   */
  private _licences: Map<string, LicenceDto> = new Map<string, LicenceDto>();

  /**
   * map to hold all licence types
   */
  private _licenceTypes: Map<number, LicenceType> = new Map<
    number,
    LicenceType
  >();

  /**
   * map to hold all licence statuses
   */
  private _licenceStatuses: Map<number, LicenceStatus> = new Map<
    number,
    LicenceStatus
  >();

  /**
   * map to hold all companies
   */
  private _companies: Map<number, Company> = new Map<number, Company>();

  /**
   * cosntructor
   *
   * @param _restService injected
   */
  constructor(private _restService: RestService, private http: HttpClient, private _langService: LanguageService,) {}

  /**
   * canActivate method for the angular router
   * see app-routing.module.ts where it is used
   *
   * @param route
   * @param state
   * @returns
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // check if any data are missing
    if (
      this._licenceTypes.size === 0 ||
      this._licenceStatuses.size === 0 ||
      this._optionTypes.size === 0 ||
      this._optionVersions.size === 0 ||
      this._companies.size === 0 ||
      this._licences.size === 0 ||
      this._options.size === 0
    ) {
      // load all data from the backend and update internal maps
      // TODO: maybe clear the maps before they are updated, to make sure deleted entries are no longer present
      return forkJoin([
        this._getLicenceTypes(),
        this._getLicenceStatuses(),
        this._getOptionTypes(),
        this._getOptionVersions(),
        this._getCompanies(),
        this._getLicences(),
        this._getOptions(),
      ]).pipe(
        map(
          ([
            licenceTypes,
            licenceStatuses,
            optionTypes,
            optionVersions,
            companies,
            licences,
            options,
          ]) => {
            // update licence types
            for (let licenceType of licenceTypes) {
              this._licenceTypes.set(licenceType.id as number, licenceType);
            }

            // update licence statuses
            for (let licenceStatus of licenceStatuses) {
              this._licenceStatuses.set(licenceStatus.id, licenceStatus);
            }

            // update option types
            for (let optionType of optionTypes) {
              this._optionTypes.set(optionType.id as number, optionType);
            }

            // update option versions
            for (let optionVersion of optionVersions) {
              this._optionVersions.set(optionVersion.id, optionVersion);
            }

            // update companies
            for (let company of companies) {
              this._companies.set(company.id, company);
            }

            for (let licence of licences) {
              this._licences.set(licence.guid, licence);
            }

            for (let option of options) {
              this._options.set(option.guid, option);
            }
          }
        )
      );
    } else {
      return of(true);
    }
  }

  /**
   * _getOptionVersions returns a request to the backend for option versions
   *
   * @returns
   */
  private _getOptionVersions() {
    return this._restService.get<OptionVersion[]>('/data/option-versions');
  }

  /**
   * _getOptionTypes returns a request to the backend for option types
   * @returns
   */
  private _getOptionTypes() {
    return this._restService.get<OptionType[]>('/data/option-types');
  }

  /**
   * _getLicenceTypes returns a request to the backend for liceces types
   *
   * @returns
   */
  private _getLicenceTypes() {
    return this._restService.get<LicenceType[]>('/data/licence-types');
  }

  /**
   * _getLicenceStatuses returns a request to the backend for licence statuses
   * @returns
   */
  private _getLicenceStatuses() {
    return this._restService.get<LicenceStatus[]>('/data/licence-statuses');
  }

  /**
   * _getLicences returns a request to the backend for licence statuses
   * @returns
   */
  private _getLicences() {
    return this._restService.get<LicenceDto[]>('/data/licences');
  }

  /**
   * _getCompanies returns a request to the backend for companies
   *
   * @returns
   */
  private _getCompanies() {
    return this._restService.get<Company[]>('/data/companies');
  }

  /**
   * _getOptions returns a request to the backend for options
   *
   * @returns
   */
  private _getOptions() {
    return this._restService.get<OptionFormDto[]>('/data/options');
  }

  /**
   * _getOptions returns a request to the backend for options
   *
   * @returns
   */
  private _getOptionsByLicence(guid: string) {
    return this._restService.get<OptionFormDtoUpperCase[]>(
      `/licences/${guid}/options`
    );
  }

  /**
   * getter for an iteratable object of the values for option versions
   */
  get optionVersions() {
    return this._optionVersions;
  }

  /**
   * getter function for specific option versions per id
   *
   * @param id
   * @returns
   */
  getOptionVersionById(id: number) {
    return this._optionVersions.get(id);
  }

  /**
   * getter for an iteratable object of the values for option types
   */
  get optionTypes() {
    return this._optionTypes;
  }

  /**
   * getter for a specific option type by id
   *
   * @param id
   * @returns
   */
  getOptionTypeById(id: number) {
    return this._optionTypes.get(id);
  }

  /**
   * getter for an iteratable object of the values for licence types
   */
  get licenceTypes() {
    return this._licenceTypes;
  }

  /**
   * getter for a specific licence type by id
   *
   * @param id
   * @returns
   */
  getLicenceTypeById(id: number) {
    return this._licenceTypes.get(id);
  }

  getLicenceTypes() {
    return this._licenceTypes
  }

  /**
   * getter for an iteratable object of the values for licence statuses
   */
  get licenceStatuses() {
    return this._licenceStatuses;
  }

  getLicenceTypesRest() {
    return this._restService.get<LicenceTypeUpper[]>("/licences/licencetypes")
  }

  getOptionTypesRest() {
    return this._restService.get<OptionTypeUpper[]>("/licences/optiontypes")
  }

  getOptionTypeByIDRest(id: number) {
    return this._restService.get<OptionTypeUpper>(`/licences/optiontype/${id}`)
  }

  /**
   * getter for a specific licence status by id
   *
   * @param id
   * @returns
   */
  getLicenceStatusById(id: number) {
    return this._licenceStatuses.get(id);
  }

  getLicenceStatusByIdTranslate(id: number) {
    return 'stats.' + this._licenceStatuses.get(id)?.status;
  }

  /**
   * get map of companies
   */
  get companies() {
    return this._companies;
  }

  /**
   * get company by id
   *
   * @param id
   * @returns
   */
  getCompanyById(id: number) {
    return this._companies.get(id);
  }

  /**
   * getter for a specific licence by id
   *
   * @param guid
   * @returns
   */
  getLicenceById(guid: string) {
    return this._licences.get(guid);
  }

  /**
   * getter for optionlist
   * @returns
   */
  getoptions() {
    return this._options;
  }

  /**
   * getter for a specific licence by id
   *
   * @param guid
   * @returns
   */
  getOptionsByLicence(guid: string) {
    return this._getOptionsByLicence(guid);
  }


  getUser(guid: string) {
    return this._restService.get<UserDto>(
      `/auth/user/${guid}`
    );
  }

  GetAdministratorTag(id: number) {
    return this._restService.get<boolean>(
      `/data/administrator/${id}`
    )
  }

  getContacts() {
    return this._restService.get<Contact[]>(
      `/clients/contacts`
    )
  }

  getUsers() {
    return this._restService.get<UserUpper[]>(
      `/data/users`
    )
  }

  getRoles() {
    return this._restService.get<Role[]>(
      `/data/roles`
    )
  }

  getTags() {
    return this._restService.get<Tag[]>(
      `/data/tags`
    )
  }


  getTagsByUser(id: number, group?: string) {
    if(group) {
      return this._restService.get<Tag[]>(
        `/data/tags/${id}/${group}`
      )
    }
    return this._restService.get<Tag[]>(
      `/data/tags/${id}`
    )
  }


  getRoleByUser(role_id: number) {
    return this._restService.get<Role>(
      `/data/role/${role_id}`
    )
  }

  getContactByID(id: number) {
    return this._restService.get<Contact>(
      `/data/contact/${id}`
    )
  }

  getOrdered(id: number) {

  }

  downloadCSV(guid: string, urlPrefix: string, data: ExportCSV) {
    const lang = this._langService.currentLanguage

    let fileName = data.client.company.name
    if(urlPrefix == "customer") {
      fileName = data.customers[0].company.name
    } if (urlPrefix == "licence") {
      fileName = data.licences[0].Project
    }
    let now = new Date().toISOString().split("T")[0]
    fileName = fileName + "_" + now
    return this._restService
      .downloadFile(`/dashboard/${urlPrefix}/${guid}/export/${lang}`, data)
      .subscribe(
        (blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}.csv`;
          // You can set a dynamic filename here
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Download error:', error);
        }
      );
  }

  exportOpt (vrs: string) {
    const lang = this._langService.currentLanguage
    return this._restService.downloadFile(`/dashboard/licence/export/${vrs}/opt/${lang}`, vrs).subscribe(
      (blob: Blob) => {
        const url = window.URL.createObjectURL(new Blob([blob], {type: 'text/plain'}));
        const a = document.createElement('a');
        a.href = url;
        a.download = `LicenceFeatures_${vrs}.opt`;
        // You can set a dynamic filename here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Download error:', error);
      }
    );
  }

  async export_pdf(data: HTMLElement, type: string, guid: string) {
    data.style.backgroundColor = "white !important"
    const header = new Image();
    header.src = '/assets/pdf_header.png';
    const footer = new Image();
    footer.src = '/assets/pdf_footer.png';

    const loadImage = (img: HTMLImageElement) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    try {
      await loadImage(header);
      await loadImage(footer)

      const canvas = await html2canvas(data, {scale: 1.2});
      const imgWidth = 188; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      pdf.setFillColor("#ffffff")

      const headerHeight = (header.naturalHeight * imgWidth) / header.naturalWidth;
      const footerHeight = (footer.naturalHeight * imgWidth) / footer.naturalWidth;

      pdf.addImage(header.src, 'PNG', 10, 0, imgWidth, headerHeight);
      pdf.addImage(contentDataURL,'PNG', 10, headerHeight + 10, imgWidth, imgHeight
      );
      pdf.addImage(footer.src, 'PNG', 10, 295 - footerHeight, imgWidth, footerHeight)
      const now = new Date().toLocaleDateString()
      pdf.save('PDF-' + type + '-Summary_' + guid + "_" + now + ".pdf"); // Generated PDF
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }

  resetPassword(email: string) {
  return this._restService.get(`/email/reset/${email}`)
  }

  resetPasswordConfirm(password: string, uuid: string) {
    return this._restService.put(`/user/reset`, {Password: password, UUID: uuid})
  }

  test(data:any) {
    return this._restService.post(`/usage/report`, data)
  }
}
