<h1 mat-dialog-title class="dialog-header" *ngIf="!guid">{{ "dialog.addlicence" | translate }}</h1>
<h1 mat-dialog-title class="dialog-header" *ngIf="guid">{{ "dialog.editlicence" | translate }}</h1>

<mat-dialog-content class="mat-typography">
  <mat-stepper [linear]="true" #stepper (selectedIndexChange)="onStepChange($event)">
    <mat-step [stepControl]="clientSelectForm">
      <ng-template matStepLabel>{{"Client" | translate}}</ng-template>

      <h3>{{"dialog.selectclient" | translate}}</h3>

      <app-dialog-company-select
        [form]="clientSelectForm"
      ></app-dialog-company-select>

      <div class="float-right">
        <button mat-button mat-dialog-close (click)="resetData()">{{"Cancel" | translate}}</button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
          {{"Next" | translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="customerSelectForm">
      <ng-template matStepLabel>{{"Customer" | translate}}</ng-template>

      <app-dialog-customer-select
        [form]="customerSelectForm" [currentCustomer]="initialCustomerId" [selectedClient]="initialClientId" [customerInformation]="initialCustomer" [clientForm]="clientSelectForm"
      ></app-dialog-customer-select>

      <div class="float-right">
        <button mat-button mat-dialog-close (click)="resetData()">{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Next" | translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="licenceForm">
      <ng-template matStepLabel>{{"Licence" | translate}}</ng-template>

      <app-dialog-licence-form [form]="licenceForm" [initialLicence]="initialLicence"></app-dialog-licence-form>

      <div class="float-right">
        <button mat-button mat-dialog-close (click)="resetData()">{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2">{{"Next" | translate}}</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="optionsForm">
      <ng-template matStepLabel>{{"LicenceOptions" | translate}}</ng-template>

      <app-dialog-options-form [form]="optionsForm" *ngIf="currentStepIndex === 3"></app-dialog-options-form>

      <div class="float-right">
        <button mat-button mat-dialog-close (click)="resetData()">{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          matStepperNext
          cdkFocusInitial
          color="primary"
          class="ml-2">{{"Next" | translate}}</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{"Confirmation" | translate}}</ng-template>
      <app-dialog-verify-licence *ngIf="currentStepIndex === 4"></app-dialog-verify-licence>

      <div class="float-right">
        <button mat-button mat-dialog-close (click)="resetData()">{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
        {{"Back" | translate}}
        </button>
        <button
          mat-flat-button
          cdkFocusInitial
          color="primary"
          class="ml-2"
          (click)="save()"
        >
        {{"Save" | translate}}
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
