import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChildrenOutletContexts } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private messageDurationInSeconds: number = 10;
  constructor(
    private _snackBar: MatSnackBar,
    private _notificationService: NotificationService,
    private _translate: TranslateService,
    private _contexts: ChildrenOutletContexts
  ) {
    this._notificationService.messages.subscribe((message) => {
      this._snackBar.openFromComponent(NotificationComponent, {
        verticalPosition: 'top',
        duration: message.options?.duration ?? 10000,
        data: message
      });
    });
  }
}
