import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { AddClientDto } from 'src/app/models/dialog-dto/add-client.dto';
import { CompanyFormDto } from 'src/app/models/dialog-dto/company-form.dto';
import { AddCustomerDto } from 'src/app/models/dialog-dto/add-customer.dto';
import { Tag } from 'src/app/models/user';
import { DialogService } from 'src/app/services/dialog.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dialog-company-form',
  templateUrl: './dialog-company-form.component.html',
  styleUrl: './dialog-company-form.component.scss'
})
export class DialogCompanyFormComponent {
  @Input()
  form: FormGroup;

  @Input()
  tags: Tag[] = []

  @Input()
  initialClient?: CompanyFormDto | undefined

  @Output()
  changeEvent = new EventEmitter<[Tag[]]>()

  availableTags: Tag[] = []

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    private dataService: DataService,
  ) {
    this.form = dialogFormService.newCompanyForm();

    this.dataService.getTags().subscribe(tags => {this.availableTags = tags})
  }

  appendTag(tag: Tag) {
    let found = false
    this.tags.forEach(t => {
      if(t.ID == tag.ID) {
        found = true
      }
    })
    if(!found) {
      this.tags.push(tag)
    }
    this.changeEvent.emit([this.tags])
  }

  removeTag(tag: Tag) {
    if(this.tags.includes(tag)) {
      this.tags = this.tags.filter(t => t != tag)
    }
    this.changeEvent.emit([this.tags])
  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue("")
    }
  }
}
